import {makeStyles, fade} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    downloadButton:{
        color:'#009688',
        fontSize:'0.875rem',
        padding: 8,
        textTransform: 'uppercase',
        textDecoration: 'none',
        fontWeight: 500,
        '&:hover':{
            backgroundColor:'rgba(0, 150, 136, 0.04)',

        }
    }
}));

export default useStyles;