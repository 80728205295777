import React, {useState} from 'react';
import useStyles from "./styles";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Typography from "@material-ui/core/Typography";
import { TextField } from 'formik-material-ui';
import { Button, Grid } from '@material-ui/core';
import * as Yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import instance, {BASE_URL, JSON_CONFIG} from "./../../axios";

const feedbackSchema = Yup.object().shape({
    name: Yup.string().required('Full name is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    message:Yup.string().required('Message is required'),
});

export default () => {
    const classes = useStyles();

    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const callSubmitApi=(values, resetForm)=>{
        instance.post(`${BASE_URL}/contact`,values, JSON_CONFIG)
            .then(
                response => {
                    setMessageType('success');
                    setMessage(response.data.message);
                    setShowMessage(true);
                    resetForm({values:''});
                },
                error => {
                    setMessageType('error');
                    setMessage(error.response.data.message);
                    setShowMessage(true);
                }
            )
            .catch(
                error => {
                    setMessageType('error');
                    setMessage(error.response.data.message);
                    setShowMessage(true);
                }
            )

    }

    return(<div className={classes.root}>
        <Typography variant="h5" component="h1" style={{marginTop:15, marginBottom:22}}>
            Feedback
        </Typography>
        <Formik
            initialValues={{ name: '', email: '', message:'' }}
            validationSchema={feedbackSchema}
            onSubmit={(values,{setSubmitting, resetForm}) => {
                setSubmitting(false);
                callSubmitApi(values, resetForm);
            }}
        >
            {({ errors,
                  submitForm,
                  isSubmitting
              }) => {
                return(
                <Form>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {!!showMessage && <Alert variant="outlined" severity={messageType}>{message}</Alert>}
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                component={TextField}
                                name="name"
                                label="Full Name*"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                component={TextField}
                                name="email"
                                type="email"
                                label="Email*"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                component={TextField}
                                name="message"
                                label="Comments/Feedback*"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                multiline
                                rows={8}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained"
                                size="large"
                                color="primary"
                                className={classes.margin}
                                onClick={submitForm}
                                disabled={isSubmitting}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}}
        </Formik>
    </div>)
}


