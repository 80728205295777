import {makeStyles, fade} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root:{
        padding:theme.spacing(2),
        margin:'0 auto',
        maxWidth:1150
    },
    title: {
        // fontSize:16,
        fontWeight:400,
        "& a":{
            color:'#009688',
            "&:hover":{
                textDecoration:"none"
            }
        }
    },
    itemTitle:{
        color:'#009688',
    },
    country: {
        fontSize: 15,
        fontWeight: 400,
        marginBottom:20,
        color:'#009688'
    },
    searchResultTerm:{
      margin:theme.spacing(1,2),
        fontWeight:400
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
            marginLeft: theme.spacing(1),
            width: 'auto',
        flex: 1,
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        display:'flex'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('flex'),
        flex: 0.5,
        '&:focus': {
            flex:1
                },
        // [theme.breakpoints.up('sm')]: {
        //     width: '12ch',
        //     '&:focus': {
        //         width: '50ch',
        //     },
        // },
    },
    cardRoot:{
        background:"#eeeeee",
        marginBottom:theme.spacing(2),
        transition: theme.transitions.create(['background','border']),
        "&:hover":{
            border:`${theme.palette.primary.main} 1px solid`,
            background:"white"
        }

    },
    cardContent:{
        "& strong":{
            fontWeight:500
        }
    }
}));

export default useStyles;