import dev from "./configureStore.dev";
import pro from "./configureStore.prod";

let store = null;
if (process.env.NODE_ENV === "production") {
    store = pro;
} else {
    store = dev;
}

export default store;
