import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import SliderYearMultiRange from "../../sliderYearMultiRange";
import {
  COMPARE_HEADER,
  filterExportData,
  generateCSV,
  selectedFlag,
  numberWithCommas,
} from "../../../utils";
import API from "../../app/actions";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";

const PanelContent2 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    countries,
    indicator,
    category,
    subCategory,
    indicatorAPIData,
    multiRangeYear,
  } = useSelector((state) => state.App);
  const [tableData, setTableData] = useState([]);
  const [preparedTableData, setPreparedTableData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDownloadMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!indicatorAPIData.length) {
      dispatch(API.getIndicatorApiData());
    }
  }, []);

  useEffect(() => {
    if (countries.length && indicatorAPIData.length) {
      let filteredData = [];
      console.log("multiRangeYear", multiRangeYear);
      countries.map((selectedCountry) => {
        let data = [];
        data = indicatorAPIData.filter((record) => {
          return (
            category.id == record.category_id &&
            subCategory.child_id == record.subcategory_id &&
            indicator.id == record.indicator_id &&
            record.year >= multiRangeYear[0] &&
            record.year <= multiRangeYear[1] &&
            record.code.toUpperCase() == selectedCountry.code.toUpperCase()
          );
        });
        if (data.length) filteredData = [...filteredData, ...data];
      });
      console.log("filteredData", filteredData);

      //PREPARE DATA FOR TABLE
      if (filteredData.length) {
        let prepareTableData = [];
        // filteredData.map(outer=>{
        //let preparedRow = {};
        let yearValueArr = [];
        filteredData.map((inner) => {
          if (
            category.id == inner.category_id &&
            subCategory.child_id == inner.subcategory_id &&
            indicator.id == inner.indicator_id
          ) {
            let val = inner.value.toFixed(2);
            yearValueArr.push({
              value: numberWithCommas(val),
              year: inner.year,
              country: inner.country,
              unit: inner.unit,
            });
          }
        });
        // preparedRow = {...preparedRow, yearValueArr}
        console.log("preparedRow", yearValueArr);
        if (prepareTableData.length) {
          const result = prepareTableData.filter((item) => {
            return (
              item.category_id == yearValueArr.category_id &&
              item.subcategory_id == yearValueArr.subcategory_id &&
              item.indicator_id == yearValueArr.indicator_id
            );
          });

          if (result.length === 0) {
            prepareTableData.push(yearValueArr);
          }
        } else {
          prepareTableData.push(yearValueArr);
        }

        // });

        let transformedRowData = [];
        for (let i = multiRangeYear[0]; i <= multiRangeYear[1]; i++) {
          let rowYearData = _.filter(prepareTableData[0], function (o) {
            return o.year == i;
          });
          transformedRowData.push({
            year: i,
            row: rowYearData,
          });
        }
        setPreparedTableData(transformedRowData);
        console.log("transformedRowData", transformedRowData);
      }

      //PREPARE DATA FOR CSV DOWNLOAD
      if (filteredData.length) {
        const transformData = filteredData.map((row, index) => {
          return {
            number: index + 1,
            category: row.category,
            subcategory: row.subcategory,
            indicator_name: row.indicator_name,
            value: row.value,
            unit: row.unit,
            country: row.country,
            year: row.year,
          };
        });
        setTableData(transformData);
      } else {
        setTableData(filteredData);
      }
    }

    if (_.isEmpty(indicator)) {
      setTableData([]);
      setPreparedTableData([]);
    }
  }, [
    indicator,
    category,
    subCategory,
    countries,
    multiRangeYear,
    indicatorAPIData,
  ]);

  const downlaodCSVHandler = (e) => {
    // Download CSV button click

    if (indicatorAPIData.length) {
      let transformData = [];
      let dataToExport = [];
      if (e.target.value === 1) {
        transformData = indicatorAPIData.map((row, index) => {
          return {
            number: index + 1,
            category: row.category,
            subcategory: row.subcategory,
            indicator_name: row.indicator_name,
            value: row.value,
            unit: row.unit,
            country: row.country,
            year: row.year,
          };
        });

        //Downlaod all data
        dataToExport = [COMPARE_HEADER, ...transformData];
      } else if (e.target.value === 2) {
        //Download selected data
        dataToExport = [COMPARE_HEADER, ...tableData];
      }
      dataToExport = filterExportData(dataToExport);
      generateCSV("Compare Indicators", dataToExport);
      handleDownloadMenuClose();
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Year {`${multiRangeYear[0]}-${multiRangeYear[1]}`}
          </Typography>
          <Button
            color="secondary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleDownloadMenuClick}
          >
            Download as CSV
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleDownloadMenuClose}
          >
            <MenuItem value={1} onClick={downlaodCSVHandler}>
              All Data
            </MenuItem>
            <MenuItem
              value={2}
              onClick={downlaodCSVHandler}
              disabled={!!tableData && !tableData.length}
            >
              Selected Only
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <div className={classes.tableCnt}>
        <Table
          className={classes.table}
          aria-label="Data compare records table"
        >
          <TableHead>
            <TableRow>
              <TableCell component="th">Year</TableCell>
              <TableCell component="th">Unit</TableCell>
              {countries.map((country, index) => (
                <TableCell component="th" key={country.id} align="center">
                  <img
                    src={selectedFlag(country.code)}
                    alt={country.name}
                    className={classes.flag}
                  />
                  {country.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {preparedTableData.map((data, index) => {
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <strong>{data.year}</strong>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <strong>
                      {data.row.length > 0 ? data.row[0].unit : " "}
                    </strong>
                  </TableCell>
                  {data.row.length > 0 ? (
                    data.row.map((value, index) => {
                      return (
                        <TableCell component="th" key={index} align="center">
                          {value.value}
                        </TableCell>
                      );
                    })
                  ) : (
                    <TableCell component="th" key={index} align="center">
                      &nbsp;
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {React.useMemo(
        () => (
          <SliderYearMultiRange multiRangeYear={multiRangeYear} />
        ),
        [multiRangeYear]
      )}
    </div>
  );
};

export default PanelContent2;
