import React, {useEffect, useRef} from 'react';
import useStyles from "./styles";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from "@material-ui/core/Grid";
import {
    Button,
    MenuItem,
    Typography,
    FormControl,
    InputLabel
} from '@material-ui/core';
// import XLSX from 'xlsx';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import {TextField, Select} from "formik-material-ui";
import {Field, Form, ErrorMessage , Formik} from "formik";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {BASE_HOST} from './../../../axios';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const downloadSchema = Yup.object().shape({
    countryList: Yup.array().required('Select country'),
    yearsList: Yup.array().required('Select year')
});

export default ({open, handleClose, content, maxWidth}) => {
    const classes = useStyles();
    const {
        countriesList,
        allSubCategories
    } = useSelector((state) => state.App);

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [downloadType, setDownloadType] = React.useState('');

    useEffect(() => {
        setLeft(allSubCategories);
    },[allSubCategories]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items, type) => (
        // <Paper className={classes.paper}>
            <List dense component="ul" role="list" className={classes.customList}>
                {items.map((value, index) => {

                    return (

                        <ListItem key={`${value}_${type}_${index}`} component="li" disableGutters role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': value.subcategory_id }}
                                />
                            </ListItemIcon>
                            <ListItemText id={value.subcategory_id} primary={value.subcategory} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        // </Paper>
    );
    // console.log('downloadType',downloadType);
    const handleDownload=(values)=>{
        // console.log(values);
        // console.log(right);
        // console.log(downloadType);
        window.location.href = `${BASE_HOST}/generate-${downloadType}?years=${values.yearsList.toString()}&countries=${values.countryList.toString()}&indicators=${right.map(cat=>cat.subcategory_id).toString()}`;
        setDownloadType('');
        handleClose();
    }

    const yearsItemList=()=>{
        let result=[];
        for(let i=2000; i<=2020; i++){
            result.push(<MenuItem className={classes.dropDownItem} key={i} value={i}>{i}</MenuItem>)
        }
        return result;
    }

    return(
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth='lg'
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Download Selective Data as CSV
            </DialogTitle>

            <Formik
                initialValues={{ countryList:[], yearsList: []}}
                validationSchema={downloadSchema}
                onSubmit={(values,{setSubmitting, resetForm}) => {
                    setSubmitting(false);
                    handleDownload(values);
                }}
                
            >
                {({ errors,
                      submitForm,
                      isSubmitting
                  }) => { 
                    return(
                        <Form>
                            <DialogContent dividers>
                                <Grid container spacing={2} fullWidth style={{marginBottom:20}}>
                                    <Grid item xs={5} >
                                        <FormControl fullWidth>
                                            <InputLabel shrink={true} htmlFor="countryList">
                                                Select Country/Countries
                                            </InputLabel>
                                        <Field
                                            component={Select}
                                            name="countryList"
                                            fullWidth
                                            variant="standard"
                                            margin="dense"
                                            multiple={true}
                                            className={classes.field}
                                            inputProps={{id:'countryList'}}
                                            error={Boolean(errors.countryList)}
                                        >
                                            {
                                                countriesList.map(option=>(
                                                <MenuItem className={classes.dropDownItem} key={`${option.id}_modal`} value={option.id}>{option.name}</MenuItem>
                                                ))
                                            }
                                        </Field>
                                            <span> <ErrorMessage name="countryList" /> </span>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2} ></Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink={true} htmlFor="yearsList">
                                                Select Year(s)
                                            </InputLabel>
                                        <Field
                                            component={Select}
                                            name="yearsList"
                                            fullWidth
                                            variant="standard"
                                            margin="dense"
                                            multiple={true}
                                            className={classes.field}
                                            inputProps={{id:'yearsList'}}
                                            error={Boolean(errors.yearsList)}
                                        >
                                            {yearsItemList()}

                                        </Field>
                                           <span> <ErrorMessage name="yearsList" /> </span>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                                    <Grid item xs={5} >
                                        <Typography variant="subtitle2">
                                            Categories
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} ></Grid>
                                    <Grid item xs={5}>
                                        <Typography  variant="subtitle2">
                                            Selected Categories
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={0} fullWidth>
                                    <Grid item xs={5} ><SimpleBar style={{ maxHeight: 200 }}>{customList(left,'left')}</SimpleBar></Grid>
                                    <Grid item xs={2} >
                                        <Grid container direction="column" alignItems="center">
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classes.button}
                                                onClick={handleAllRight}
                                                disabled={left.length === 0}
                                                aria-label="move all right"
                                            >
                                                ≫
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classes.button}
                                                onClick={handleCheckedRight}
                                                disabled={leftChecked.length === 0}
                                                aria-label="move selected right"
                                            >
                                                &gt;
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classes.button}
                                                onClick={handleCheckedLeft}
                                                disabled={rightChecked.length === 0}
                                                aria-label="move selected left"
                                            >
                                                &lt;
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classes.button}
                                                onClick={handleAllLeft}
                                                disabled={right.length === 0}
                                                aria-label="move all left"
                                            >
                                                ≪
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={5}><SimpleBar style={{ maxHeight: 200 }}>{customList(right,'right')}</SimpleBar></Grid>
                                </Grid>



                            </DialogContent>
                            <DialogActions>
                                <Button color="primary" type='submit' onClick={()=>setDownloadType('excel')}>
                                    Download Excel
                                </Button>
                                <Button color="primary" type='submit' onClick={()=>setDownloadType('csv')}>
                                    Download CSV
                                </Button>
                                <Button onClick={handleClose} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                                    </Form>
                                    )}}
                                        </Formik>
        </Dialog>
    )
}


