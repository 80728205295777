import React from 'react';
import VerticalRightTabs from './../verticalRightTabs';
import useStyles from './styles';

const DataIndicatorsPage = (props) => {
    const classes = useStyles();
    return(
        <div>
            <div className={classes.root}>
                <VerticalRightTabs/>
            </div>
        </div>
    )
};

export default DataIndicatorsPage;
