import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, NavLink } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import useStyles from "./styles";
import Logo from "../../resources/images/logo.png";
import { Container } from "@material-ui/core";
import CountryBar from "../countryBar";

export default ({ handleDrawerOpen }) => {
  const location = useLocation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorPolicyEl, setAnchorPolicyEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openPolicy = Boolean(anchorPolicyEl);

  const handleMenu = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handlePolicyMenu = (event) => {
    event.preventDefault();
    setAnchorPolicyEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePolicyClose = () => {
    setAnchorPolicyEl(null);
  };

  return (
    <Container disableGutters className={classes.root}>
      <Link to="/" className={classes.logo}>
        <div style={{ flex: 1 }}>
          <img src={Logo} alt="" className={classes.logoImg} />
        </div>

        <div className={classes.logoText}>
          Saarc
          <br />
          Energy
          <br />
          Centre
        </div>
      </Link>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          {/*<IconButton*/}
          {/*    edge="start"*/}
          {/*    className={classes.locationOnButton}*/}
          {/*    color="inherit"*/}
          {/*    aria-label="Location On"*/}
          {/*    onClick={handleDrawerOpen}*/}
          {/*>*/}
          {/*    <MenuIcon />*/}
          {/*</IconButton>*/}
          <NavLink
            exact={true}
            activeClassName="is-active"
            className={classes.navLink}
            to="/"
          >
            Home
          </NavLink>
          <NavLink
            activeClassName="is-active"
            className={`${classes.navLink} ${classes.arrowLink}`}
            to="/data"
            onClick={handleMenu}
          >
            <span>Data</span> <ExpandMoreIcon />
          </NavLink>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={open}
            onClose={handleClose}
          >
            <div className={classes.dropDownMenu}>
              <NavLink
                activeClassName="is-active"
                className={classes.navLink}
                to="/data/indicators"
                onClick={handleClose}
              >
                Indicators
              </NavLink>
              <NavLink
                activeClassName="is-active"
                className={classes.navLink}
                to="/data/energy-trade-flow"
                onClick={handleClose}
              >
                Energy Trade Flow
              </NavLink>
              <NavLink
                activeClassName="is-active"
                className={classes.navLink}
                to="/data/compare"
                onClick={handleClose}
              >
                Compare
              </NavLink>
            </div>
          </Menu>
          <NavLink
            activeClassName="is-active"
            className={classes.navLink}
            to="/inframaps"
          >
            Inframaps
          </NavLink>
          <NavLink
            activeClassName="is-active"
            className={`${classes.navLink} ${classes.arrowLink}`}
            onClick={handlePolicyMenu}
            to="/policy"
          >
            <span>Policy</span> <ExpandMoreIcon />
          </NavLink>
          <Menu
            anchorEl={anchorPolicyEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={openPolicy}
            onClose={handlePolicyClose}
          >
            <div className={classes.dropDownMenu}>
              <NavLink
                activeClassName="is-active"
                className={classes.navLink}
                to="/policy/timeline"
                onClick={handlePolicyClose}
              >
                Timeline
              </NavLink>
              <NavLink
                activeClassName="is-active"
                className={classes.navLink}
                to="/policy/search"
                onClick={handlePolicyClose}
              >
                Search
              </NavLink>
            </div>
          </Menu>
          {/* eslint-disable-next-line no-undef */}
          <NavLink
            activeClassName="is-active"
            className={`${classes.navLink} ${
              window.hideExperts ? "hidden" : null
            }`}
            to="/experts"
          >
            Experts
          </NavLink>
          <NavLink
            activeClassName="is-active"
            className={classes.navLink}
            to="/resource-maps"
          >
            Resource Maps
          </NavLink>
          <NavLink
            activeClassName="is-active"
            className={classes.navLink}
            to="/feedback"
          >
            Feedback
          </NavLink>
          <NavLink
            activeClassName="is-active"
            className={classes.navLink}
            to="/faqs"
          >
            FAQS
          </NavLink>
          <NavLink
            activeClassName="is-active"
            className={classes.navLink}
            to="/calorific"
          >
            Calorific Values
          </NavLink>
        </Toolbar>
        <CountryBar location={location} />
      </AppBar>
    </Container>
  );
};
