export const GET_LEFT_MENU = 'GET_LEFT_MENU';
export const GET_COUNTRIES_LIST = 'GET_COUNTRIES_LIST';
export const GET_INDICATOR_API_DATA = 'GET_INDICATOR_API_DATA';
export const RESET_COUNTRIES = 'RESET_COUNTRIES';
export const SET_COUNTRIES_FOR_MAP = 'SET_COUNTRIES_FOR_MAP';
export const SET_YEAR_FOR_MAP = 'SET_YEAR_FOR_MAP';
export const SET_MULTI_RANGE_INFRAMAP = 'SET_MULTI_RANGE_INFRAMAP';
export const GET_POWER_PLANTS_DATA = 'GET_POWER_PLANTS_DATA';
export const GET_RESERVES_DATA = 'GET_RESERVES_DATA';
export const SET_SELECTED_POWER_RESERVES_INDICATORS = 'SET_SELECTED_POWER_RESERVES_INDICATORS';
export const RESET_INFRAMAPS = 'RESET_INFRAMAPS';
export const CLEAR_ALL_COMPARE_INDICATORS = 'CLEAR_ALL_COMPARE_INDICATORS';
export const SET_SELECTED_TRADE_FLOW_INDICATORS = 'SET_SELECTED_TRADE_FLOW_INDICATORS';
export const GET_TRADE_FLOW_API_DATA = 'GET_TRADE_FLOW_API_DATA';
export const SET_TRADE_FLOW_INDICATORS = 'SET_TRADE_FLOW_INDICATORS';
export const SET_EXCLUSIVE_COUNTRY = 'SET_EXCLUSIVE_COUNTRY';
export const SET_TRADE_FLOW_TYPE = 'SET_TRADE_FLOW_TYPE';
export const RESET_SELECTED_TRADE_FLOW_INDICATORS = 'RESET_SELECTED_TRADE_FLOW_INDICATORS';
export const SET_MULTI_RANGE_YEAR = 'SET_MULTI_RANGE_YEAR';
export const ALL_SUBCATEGORIES = 'ALL_SUBCATEGORIES';