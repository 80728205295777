import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import instance, { BASE_URL, JSON_CONFIG } from "./../../axios";
import { RESET_COUNTRIES } from "./../app/actionTypes";
import Loader from "./../Loader";
import Paper from "@material-ui/core/Paper";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import DetailsModal from "./DetailsModal";
import Menu from "./menu";
import _ from "lodash";

export default () => {
  const classes = useStyles();
  const [resultPolicy, setResultPolicy] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredPolicy, setFilteredPolicy] = useState([]);
  const [displayLoader, setDisplayLoader] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [mapItem, setMapItem] = useState({});
  const { countries, exclusiveCountry } = useSelector((state) => state.App);
  const dispatch = useDispatch();

  const callPolicyApi = (term) => {
    instance
      .get(`${BASE_URL}/policies`, JSON_CONFIG)
      .then(
        (response) => {
          response.data.length > 0
            ? setResultPolicy(response.data)
            : setResultPolicy("No Policy Added Yet");
          setDisplayLoader(false);
        },
        (error) => {
          console.log("error", error);
          setResultPolicy("No Policy Added Yet");
          setDisplayLoader(false);
        }
      )
      .catch((error) => {
        console.log("error", error);
        setResultPolicy("No Policy Added Yet");
        setDisplayLoader(false);
      });
  };

  const callPolicyIndicatorsApi = (term) => {
    instance
      .get(`${BASE_URL}/policycategories`, JSON_CONFIG)
      .then(
        (response) => {
          if (response.data.length > 0) {
            let result = response.data.map((item) => ({
              ...item,
              selected: false,
            }));

            const item = {
              selected: true,
              id: 99999999,
              name: "All Categories",
            };
            const resultWithAll = [item, ...result];

            setCategories(resultWithAll);
            setMapItem(item);
            console.log(result);
          }
          setDisplayLoader(false);
        },
        (error) => {
          console.log("error", error);
          setDisplayLoader(false);
        }
      )
      .catch((error) => {
        console.log("error", error);
        setDisplayLoader(false);
      });
  };

  useEffect(() => {
    dispatch({
      type: "SET_EXCLUSIVE_COUNTRY",
      payload: true,
    });

    return () => {
      dispatch({
        type: "SET_EXCLUSIVE_COUNTRY",
        payload: false,
      });
      dispatch({
        type: RESET_COUNTRIES,
      });
    };
  }, [exclusiveCountry]);

  useEffect(() => {
    if (_.isEmpty(resultPolicy)) {
      callPolicyApi();
    }
    if (_.isEmpty(categories)) {
      callPolicyIndicatorsApi();
    }
  }, []);

  useEffect(() => {
    if (!!resultPolicy && !_.isEmpty(mapItem)) {
      if (mapItem.id == 99999999) {
        const filterData = resultPolicy.filter(
          (item) => item.country_id == countries.id
        );
        console.log("filterData", filterData);
        setFilteredPolicy(filterData);
      } else {
        const filterData = resultPolicy.filter(
          (item) =>
            item.country_id == countries.id && item.category_id == mapItem.id
        );
        console.log("filterData", filterData);
        setFilteredPolicy(filterData);
      }
    }
  }, [countries, mapItem, resultPolicy]);

  const truncateSummary = (str) => {
    if (!_.isEmpty(str)) {
      if (str.length === 0) return str;
      else if (str.length > 215) {
        str = str.substring(0, 215);
      }
      return `${str}...`;
    } else return "";
  };

  const handleOpenDialog = (index) => {
    setDialogData(filteredPolicy[index]);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setDialogData({});
    setShowDialog(false);
  };

  const onMenuItemClickHandler = (e) => {
    const newCategories = categories.map((item) => {
      if (e.target.value == item.id) {
        item.selected = true;
        setMapItem(item);
      } else {
        item.selected = false;
      }
      return item;
    });
    setCategories(newCategories);
  };

  return (
    <>
      <div className={classes.root}>
        <Loader display={displayLoader} />
        <Menu
          categories={categories}
          onMenuItemClickHandler={onMenuItemClickHandler}
        />
        <div className={classes.timeLineCnt}>
          <Typography
            variant="h5"
            component="h1"
            style={{ margin: "16px 0 16px 16px" }}
          >
            {!_.isEmpty(countries) && !_.isEmpty(mapItem)
              ? countries.name
              : "Please select a country"}
          </Typography>
          <Timeline align="alternate">
            {!!filteredPolicy &&
              filteredPolicy.map((item, index) => {
                return (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent>
                      <Typography variant="h6" component="h1" color="primary">
                        {item.year}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot variant="outlined" color="primary" />
                      {filteredPolicy.length !== index + 1 ? (
                        <TimelineConnector />
                      ) : null}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper
                        elevation={0}
                        variant="outlined"
                        className={classes.paper}
                      >
                        <Typography
                          variant="h6"
                          component="h2"
                          className={classes.itemTitle}
                        >
                          {item.title}
                        </Typography>
                        {mapItem.id === 99999999 ? (
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.summary}
                          >
                            <em>Category: {item.category}</em>
                          </Typography>
                        ) : (
                          ""
                        )}
                        <Typography
                          variant="body2"
                          component="p"
                          className={classes.summary}
                        >
                          {truncateSummary(item.summary)}
                        </Typography>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => handleOpenDialog(index)}
                        >
                          Read more
                        </Button>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
          </Timeline>
          <Typography component="p" style={{ textAlign: "center" }}>
            {!_.isEmpty(countries) &&
              !_.isEmpty(mapItem) &&
              filteredPolicy.length === 0 &&
              "No Policy Added Yet"}
          </Typography>
        </div>
      </div>
      {showDialog && (
        <DetailsModal
          open={showDialog}
          handleClose={handleCloseDialog}
          content={dialogData}
        />
      )}
    </>
  );
};
