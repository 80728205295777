import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import useStyles from "./styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import instance, { BASE_URL, JSON_CONFIG } from "./../../axios";
import Loader from "./../Loader";

export default () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [tableDataCopy, setTableDataCopy] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [displayLoader, setDisplayLoader] = useState(false);

  const callExpertsApi = () => {
    instance
      .get(`${BASE_URL}/experts`, JSON_CONFIG)
      .then(
        (response) => {
          setTableData(response.data);
          setTableDataCopy(response.data);
          setDisplayLoader(false);
        },
        (error) => {
          console.log("error", error);
          setDisplayLoader(false);
        }
      )
      .catch((error) => {
        console.log("error", error);
        setDisplayLoader(false);
      });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filterTableData = (term) => {
    let result = tableData.filter(
      (row) =>
        (!!row.name && row.name.toLowerCase().includes(term)) ||
        (!!row.email && row.email.toLowerCase().includes(term)) ||
        (!!row.phone && row.phone.toLowerCase().includes(term)) ||
        (!!row.country &&
          !!row.country.name &&
          row.country.name.toLowerCase().includes(term))
    );
    setTableData(result);
  };

  useEffect(() => {
    setDisplayLoader(true);
    callExpertsApi();
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      setTableData(tableDataCopy);
    } else {
      filterTableData(searchTerm);
    }
  }, [searchTerm]);

  return (
    <div className={classes.root}>
      <Loader display={displayLoader} />
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Experts
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={handleSearch}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Table className={classes.table} aria-label="experts record table">
        <TableHead>
          <TableRow>
            <TableCell component="th">Sr. No.</TableCell>
            <TableCell component="th">Name</TableCell>
            <TableCell component="th">Email address</TableCell>
            <TableCell component="th">Phone</TableCell>
            <TableCell component="th">Organization</TableCell>
            <TableCell component="th">Specialization</TableCell>
            <TableCell component="th">Country</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.phone}</TableCell>
              <TableCell>{row.organization}</TableCell>
              <TableCell>{row.specialization}</TableCell>
              <TableCell>{row.country.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
