import React, {useEffect, useState} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useStyles from "./styles";
import { useSelector} from "react-redux";
import Slider from "../../slider";
import _, {isEmpty} from "lodash";
import {numberWithCommas} from './../../../utils';

require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/offline-exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

const PanelContent3 = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);

    const {
        indicator,
        countries,
        year,
        category,
        subCategory,
        indicatorAPIData } = useSelector(
        (state) => state.App
    );

    useEffect(() => {

        if (indicatorAPIData.length
            && !isEmpty(countries)
            && !isEmpty(indicator)
            && countries.length
            && !isEmpty(category)
            && !isEmpty(subCategory)) {

            const filteredSaarcDataByYear = indicatorAPIData.filter((value) => (
                category.id === value.category_id &&
                subCategory.child_id === value.subcategory_id &&
                indicator.id === value.indicator_id &&
                year === value.year
            ));
            console.log('filteredSaarcDataByYear',filteredSaarcDataByYear);

            let countriesData=[];
            countries.map((country) => {
                const filteredDataByCountry = indicatorAPIData.filter((value) => (
                        category.id === value.category_id &&
                        subCategory.child_id === value.subcategory_id &&
                        indicator.id === value.indicator_id &&
                        country.code === value.code &&
                        year === value.year
                    ));

                let sumAll = _.sumBy(filteredDataByCountry, function(o) { return o.value; });
                let valueWithoutComman = sumAll.toFixed(2);
                countriesData.push({
                    name:country.name,
                    data:[{
                        value:parseFloat(valueWithoutComman),
                        label:numberWithCommas(valueWithoutComman),
                    }]
                });
            });
            console.log('countriesData', countriesData);

            let saarcSumAll = _.sumBy(filteredSaarcDataByYear, function(o) { return o.value; });
            let saarcValueWithoutComman = saarcSumAll.toFixed(2);

            let bubbleData = {
                chart: {
                    type: "packedbubble"
                },
                title: {
                    text: `${subCategory.ind_name} ${indicator.name}-${year}`
                },
                series: [
                    {
                        name: "SAARC Region",
                        data:[{
                            value:parseFloat(saarcValueWithoutComman),
                            label:numberWithCommas(saarcValueWithoutComman),
                        }]
                    },
                    ...countriesData
                ],
                exporting:{
                    fallbackToExportServer: false,
                    accessibility:{
                        enabled:true
                    },
                    enabled:true,
                    showTable:false,
                    buttons:{
                        contextButton:{
                            align:'left',
                            x:10,
                            // menuItems:["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
                        }
                    }
                }
            }

            console.log('bubbleData',bubbleData);
            setData(bubbleData);

        }
    }, [indicator, countries, indicatorAPIData, year]);

    return (
        <div className={classes.root}>
            <HighchartsReact
                highcharts={Highcharts}
                options={data}
                containerProps={{
                    style: {
                        height: 700,
                        width: "100%",
                    },
                }}
            />
            {React.useMemo(()=> <Slider year={year}/>,[year])}
        </div>
    );
};

export default PanelContent3;
