import { makeStyles, fade } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: "0 auto",
    maxWidth: 800,
  },
  tableContainer: {
    minWidth: 500,
  },
  table: {
    border: "1px solid rgba(224, 224, 224, 1)",
    "& th": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(2, 2),
    },
  },
}));

export default useStyles;
