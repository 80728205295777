import React, { useState } from "react";
import useStyles from "./styles";
import { Button } from "@material-ui/core";
import DownloadModal from "./downloadModal";
import GetApp from '@material-ui/icons/GetApp';

export default function DownloadByIndicator() {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);

  const handleOpenDialog=(index)=>{
    setShowDialog(true);
  }

  const handleCloseDialog=()=>{
    setShowDialog(false);
  }

  return (
    <>
      <Button variant="contained"
              size="small"
              color="primary"
              className={classes.downloadButton}
              onClick={handleOpenDialog}
              startIcon={<GetApp/>}
      >
        Download
      </Button>

      {showDialog && <DownloadModal
          open={showDialog}
          handleClose={handleCloseDialog}
      />}
    </>
  );
}
