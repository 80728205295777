import {makeStyles, withStyles, useTheme} from "@material-ui/core/styles";
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
    root:{
        width:'60%',
        padding:theme.spacing(0,5),
        position:'absolute',
        left:'20%',
        bottom:50,
        zIndex:5
    }

}));

export default useStyles;

export const CustomSlider = withStyles({
    // root: {
    //     color: '#009688',
    //     height: 8,
    // },
    // thumb: {
    //     height: 24,
    //     width: 24,
    //     backgroundColor: '#fff',
    //     border: '2px solid currentColor',
    //     marginTop: -8,
    //     marginLeft: -12,
    //     '&:focus, &:hover, &$active': {
    //         boxShadow: 'inherit',
    //     },
    // },
    // active: {},
    // valueLabel: {
    //     left: 'calc(-50% + 4px)',
    // },
    // track: {
    //     height: 8,
    //     color: '#ffc935',
    //     borderRadius: 4,
    // },
    // rail: {
    //     height: 8,
    //     color: '#ffc935',
    //     opacity:1,
    //     borderRadius: 4,
    // },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -10,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    root: {
        color: '#009688',
        height: 2,
        padding: '15px 0',
    },
    active: {},
    valueLabel: {
        left: -5,
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        backgroundColor: '#009688',
    },
    mark: {
        backgroundColor: '#009688',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: '#009688',
    },
})(Slider);