import {createMuiTheme} from "@material-ui/core/styles";
import teal from "@material-ui/core/colors/teal";
import grey from "@material-ui/core/colors/grey";

const theme = createMuiTheme({
    palette: {
        primary: {
            light:teal[100],
            main: teal[500],
            dark:teal[700],
            contrastText:'#ffffff'
        },
        secondary: {
            light:grey[200],
            main: grey[300],
            dark:grey[700],
            contrastText:grey[900]
        },
    },
    overrides:{
        MuiContainer:{
            maxWidthLg:{
                maxWidth:'none !important'
            }
        },
        MuiAccordion:{
            root:{
                '&.Mui-expanded':{
                    marginTop:1,
                    marginBottom:1
                }
            }
        },
        MuiAccordionDetails:{
            root:{
                padding:0
            }
        },
        MuiAccordionSummary:{
            root:{
                '&.Mui-expanded':{
                    minHeight:48
                }
            },
            content:{
                '&.Mui-expanded':{
                    margin:'12px 0'
                }
            }
        },
        MuiTab:{
            root:{
                minWidth:'auto !important'
            }
        }
    }
});

export default theme;