import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fabIcon: {
    margin: theme.spacing(2),
    borderRadius: 5,
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "#ffc935",
    zIndex:1100
  },
  popOverTitleBar: {
    "& .MuiToolbar-regular": {
      minHeight: 48,
      display: "flex",
      justifyContent: "center",
    },
  },
  menuPopOverButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  popOverContent: {
    padding: theme.spacing(2),
  },
  infoModal: {
    backgroundColor: theme.palette.common.white,
    right: 16,
    bottom: 80,
    width: 300,
    position: "absolute",
    display: "none",
    "&.open": {
      display: "block",
      zIndex:1100
    },
  },
  chartCnt: {
    marginTop: 5,
  },
  flagContainer: {
    position: "absolute",
    left: -30,
    width:25,
    "& img":{
      width:25,
      height:25,
      display:'block',
      marginBottom:5,
      cursor:'pointer'
    }
  },
}));

export default useStyles;
