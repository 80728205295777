import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    "@global": {
        "#root": {
            height:'100vh',
        },
        ".clearfix": {
            "&:before": {
                content: "' '",
                display: "table",
            },
            "&:after": {
                content: "' '",
                clear: "both",
                display: "table",
            },
        },
    },
}));

export default useStyles;