import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  downloadButton:{
    width:110,
    zIndex:1000,
    position:'absolute',
    left:theme.spacing(1),
    bottom:theme.spacing(1)
  },
  paper: {
    width: 200,
    height: 230,
    overflow: 'auto',
  },
  root:{
    width:800
  },
  customList:{
    padding:0,
  },
  listItemIcon:{
    minWidth:40,
    '& > span':{
      padding:'0 3px'
    },
    '& svg':{
      fill:'#009688'
    }
  },
  dropDownItem:{
    '&.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover':{
      background:'#009688',
      color:'#fff'
    }
  },
  field:{
    '&.Mui-error+span':{
      color:'#f44336'
    }
  }

}));

export default useStyles;
