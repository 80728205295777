import {
  GET_LEFT_MENU,
  GET_INDICATOR_API_DATA,
  RESET_COUNTRIES,
  SET_COUNTRIES_FOR_MAP,
  GET_COUNTRIES_LIST,
  SET_YEAR_FOR_MAP,
  SET_MULTI_RANGE_INFRAMAP,
  GET_POWER_PLANTS_DATA,
  GET_RESERVES_DATA,
  SET_SELECTED_POWER_RESERVES_INDICATORS,
  SET_SELECTED_TRADE_FLOW_INDICATORS,
  RESET_INFRAMAPS,
  CLEAR_ALL_COMPARE_INDICATORS,
  GET_TRADE_FLOW_API_DATA,
  SET_TRADE_FLOW_INDICATORS,
  SET_EXCLUSIVE_COUNTRY,
  SET_TRADE_FLOW_TYPE,
  RESET_SELECTED_TRADE_FLOW_INDICATORS,
  SET_MULTI_RANGE_YEAR,
  ALL_SUBCATEGORIES,
} from "./actionTypes";

const d = new Date();
const INITIAL_MAX_YEAR = d.getFullYear() - 1;
const CURRENT_YEAR = d.getFullYear() - 3;

const initialState = {
  leftMenu: [],
  category: {},
  subCategory: {},
  indicator: {},
  cococ: {},
  countries: [],
  year: CURRENT_YEAR,
  multiRangeYear: [2000, INITIAL_MAX_YEAR],
  // seriesData: [],
  // yearData: {},
  compareIndicators: [],
  exclusiveCountry: false,
  countriesList: [],
  indicatorAPIData: [],
  multiRange: [0, 5000],
  // currentMapData:[],
  powerPlantsData: [],
  reservesData: [],
  selectedPowerReservesIndicators: [],
  selectedPowerReservesParentId: null,
  selectedTradeFlowIndicators: [],
  selectedTradeFlowParentId: null,
  tradeFlowAPIData: [],
  tradeFlowIndicators: [],
  tradeFlowType: "",
  allSubCategories: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LEFT_MENU: {
      return {
        ...state,
        leftMenu: action.payload,
      };
    }
    case "SET_CATEGORIES_FOR_MAP": {
      return {
        ...state,
        category:
          action.payload.cococ === null
            ? action.payload.category
            : {
                ...action.payload.subCategory,
                id: action.payload.subCategory.child_id,
              },
        subCategory:
          action.payload.cococ === null
            ? action.payload.subCategory
            : {
                ...action.payload.indicator,
                child_id: action.payload.indicator.child_of_child_id,
              },
        indicator:
          action.payload.cococ === null
            ? action.payload.indicator
            : action.payload.cococ,
        cococ: action.payload.cococ === null ? {} : action.payload.indicator,
      };
    }
    case SET_COUNTRIES_FOR_MAP: {
      return {
        ...state,
        countries: action.payload,
      };
    }
    case SET_YEAR_FOR_MAP: {
      return {
        ...state,
        year: action.payload,
      };
    }
    case SET_MULTI_RANGE_INFRAMAP: {
      return {
        ...state,
        multiRange: action.payload,
      };
    }
    case SET_MULTI_RANGE_YEAR: {
      return {
        ...state,
        multiRangeYear: action.payload,
      };
    }
    case "SET_SERIES_DATA": {
      return {
        ...state,
        seriesData: action.payload,
      };
    }
    // case "SET_YEAR_DATA": {
    //   return {
    //     ...state,
    //     yearData: action.payload,
    //   };
    // }
    case "SET_INDICATORS_VALUE_COMPARE": {
      return {
        ...state,
        compareIndicators: action.payload,
      };
    }
    case SET_EXCLUSIVE_COUNTRY: {
      return {
        ...state,
        exclusiveCountry: action.payload,
      };
    }
    case RESET_COUNTRIES: {
      return {
        ...state,
        countries: [],
      };
    }
    case "RESET_CONTENT": {
      return {
        ...initialState,
        leftMenu: state.leftMenu,
        countriesList: state.countriesList,
        countries: [],
      };
    }
    case GET_COUNTRIES_LIST: {
      return {
        ...state,
        countriesList: action.payload,
      };
    }
    case GET_INDICATOR_API_DATA: {
      return {
        ...state,
        indicatorAPIData: action.payload,
      };
    }
    case GET_POWER_PLANTS_DATA: {
      return {
        ...state,
        powerPlantsData: action.payload,
      };
    }
    case GET_RESERVES_DATA: {
      return {
        ...state,
        reservesData: action.payload,
      };
    }
    case SET_SELECTED_POWER_RESERVES_INDICATORS: {
      if (action.payload.add) {
        if (
          state.selectedPowerReservesParentId === null ||
          action.payload.parentId === state.selectedPowerReservesParentId
        ) {
          return {
            ...state,
            selectedPowerReservesIndicators: [
              ...state.selectedPowerReservesIndicators,
              action.payload.indicator,
            ],
            selectedPowerReservesParentId: action.payload.parentId,
          };
        } else {
          return {
            ...state,
            selectedPowerReservesIndicators: [action.payload.indicator],
            selectedPowerReservesParentId: action.payload.parentId,
          };
        }
      } else {
        if (state.selectedPowerReservesIndicators.length > 1) {
          let newArray = state.selectedPowerReservesIndicators.filter(
            (indicator) => indicator.id !== action.payload.indicator.id
          );
          return {
            ...state,
            selectedPowerReservesIndicators:
              newArray.length === 0 ? [] : newArray,
            selectedPowerReservesParentId:
              newArray.length === 0
                ? null
                : state.selectedPowerReservesParentId,
          };
        }
      }
    }
    case RESET_INFRAMAPS: {
      return {
        ...state,
        selectedPowerReservesIndicators: [],
        multiRange: [0, 5000],
        year: 2018,
      };
    }
    case CLEAR_ALL_COMPARE_INDICATORS: {
      return {
        ...state,
        compareIndicators: [],
      };
    }
    case RESET_SELECTED_TRADE_FLOW_INDICATORS: {
      return {
        ...state,
        selectedTradeFlowIndicators: [],
        selectedTradeFlowParentId: null,
      };
    }
    case SET_SELECTED_TRADE_FLOW_INDICATORS: {
      if (action.payload.add) {
        if (
          state.selectedTradeFlowParentId === null ||
          action.payload.parentId === state.selectedTradeFlowParentId
        ) {
          if (state.tradeFlowType == "" || state.tradeFlowType == "tradeMap") {
            return {
              ...state,
              selectedTradeFlowIndicators: [
                ...state.selectedTradeFlowIndicators,
                { ...action.payload.indicator, flow: action.payload.parentId },
              ],
              selectedTradeFlowParentId: action.payload.parentId,
            };
          } else {
            return {
              ...state,
              selectedTradeFlowIndicators: [
                { ...action.payload.indicator, flow: action.payload.parentId },
              ],
              selectedTradeFlowParentId: action.payload.parentId,
            };
          }
        } else {
          return {
            ...state,
            selectedTradeFlowIndicators: [
              { ...action.payload.indicator, flow: action.payload.parentId },
            ],
            selectedTradeFlowParentId: action.payload.parentId,
          };
        }
      } else {
        if (state.selectedTradeFlowIndicators.length > 1) {
          let newArray = state.selectedTradeFlowIndicators.filter(
            (indicator) => indicator.id !== action.payload.indicator.id
          );
          return {
            ...state,
            selectedTradeFlowIndicators: newArray.length === 0 ? [] : newArray,
            selectedTradeFlowParentId:
              newArray.length === 0 ? null : state.selectedTradeFlowParentId,
          };
        }
      }
    }
    case GET_TRADE_FLOW_API_DATA: {
      return {
        ...state,
        tradeFlowAPIData: action.payload,
      };
    }
    case SET_TRADE_FLOW_INDICATORS: {
      return {
        ...state,
        tradeFlowIndicators: action.payload,
      };
    }
    case SET_TRADE_FLOW_TYPE: {
      return {
        ...state,
        tradeFlowType: action.payload,
      };
    }
    case ALL_SUBCATEGORIES: {
      return {
        ...state,
        allSubCategories: action.payload,
      };
    }
    default:
      return state;
  }
};
