import {makeStyles, fade} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root:{
        padding:theme.spacing(2),
        margin:'0 auto',
        background:'rgba(255,255,255,0.6)',
        position:'fixed',
        left:0,
        right:0,
        top:0,
        bottom:0,
        overflow:'hidden',
        zIndex:99999,
        display:'none',
        "&.display":{
            display:'block'
        }
    },
    progressLoading:{
        position:'absolute',
        left:'49%',
        top:'49%',
    },
}));

export default useStyles;