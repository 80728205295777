import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root:{
        padding:theme.spacing(2),
        margin:'0 auto',
        maxWidth:600
    },
}));

export default useStyles;