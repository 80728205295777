import React, { useEffect, useState } from "react";
import _ from 'lodash';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import useStyles from "./styles";
import instance, {BASE_URL} from "./../../axios";
import {useDispatch, useSelector} from "react-redux";
import API from "../app/actions";
import {SET_TRADE_FLOW_INDICATORS} from './../app/actionTypes';

const LeftMenuInfraMap = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedTradeFlowIndicators } = useSelector((state) => state.App);
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState("");
  const [childExpanded, setChildExpanded] = useState("");

  useEffect(() => {
    instance.get(`${BASE_URL}/tradeflows/categories`)
      .then((res) => {
        setData([
          {
            parent: "Energy Trade Flows",
            id:1,
            child: [
              {
                childName: "Import",
                child_id:1,
                childOfChild: [...res.data],
              },
              {
                childName: "Export",
                child_id:2,
                childOfChild: [...res.data],
              },
            ],
          },
        ]);
        dispatch({
          type: SET_TRADE_FLOW_INDICATORS,
          payload: [...res.data],
        });
      })
      .catch((err) => {});
  }, []);

  const handleParentChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleChildChange = (panel) => (event, newExpanded) => {
    setChildExpanded(newExpanded ? panel : false);
  };


  return (
    <>
      <div className={classes.root}>
        <div className={classes.searchAndMenuCnt}>
          {data.map((value, key) => (
            <Accordion key={key}
                       expanded={true}
                       onChange={handleParentChange(value.id)}>
              <AccordionSummary
                classes={{ root: classes.summary }}
                style={{display:'none'}}
                expandIcon={<ExpandMoreIcon className={classes.white} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {value.parent}
                </Typography>
              </AccordionSummary>
              {value.child.map((child, idx) => (
                <AccordionDetails
                  key={idx}
                  className={classes.accordionMainDetails}
                >
                  <Accordion className={classes.subAccordion}
                             expanded={childExpanded === child.child_id}
                             onChange={handleChildChange(child.child_id)}
                  >
                    <AccordionSummary
                      className={classes.summary}
                      expandIcon={<ExpandMoreIcon className={classes.white} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        {child.childName}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MenuList  className={classes.menuList}>
                        {child.childOfChild.map((childOfChild,index) => (
                          <MenuItem key={`${childOfChild.name}${index}`}
                                    className={selectedTradeFlowIndicators.some(value => (value.id === childOfChild.id && value.flow == child.child_id))
                                        ? "Mui-selected"
                                        : ""}
                                    onClick={(event) => {
                                      let flag = selectedTradeFlowIndicators.some(value => (value.id === childOfChild.id && value.flow == child.child_id));
                                      dispatch(API.setSelectedTradeFlowIndicators(childOfChild, child.child_id,!flag));
                                    }}
                          >
                            <Typography
                              variant="inherit"
                              className={classes.childOfChildName}
                            >
                              {childOfChild.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              ))}
            </Accordion>
          ))}
        </div>
      </div>
    </>
  );
};

export default LeftMenuInfraMap;
