import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root:{
        flex:1,
        padding:theme.spacing(2),
        position:'relative',
        height:'calc(100vh - 90px)',
    },
    title: {
        flexGrow: 1,
    },
    tableCnt:{
        height:'calc(100vh - 280px)',
        overflow:'auto',
    },
    table:{
        border:"1px solid rgba(224, 224, 224, 1)",
        "& th":{
            padding:theme.spacing(0.5, 2)
        },
        "& strong":{
            fontWeight:500
        }
    },
    flag:{
        width: 25,
        height: 25,
        display: 'block',
        margin: '0 auto'
    }

}));

export default useStyles;