const axios = require("axios");

export const BASE_HOST = "https://portal.saarcenergy.org";
// export const BASE_HOST = 'http://epadmin.thedabsters.com/';
// export const BASE_HOST = 'http://energyportal.local:8080';
export const BASE_URL = `${BASE_HOST}/api`;

const headers = {
  "Content-Type": "multipart/form-data",
};

const instance = axios.create({
  baseURL: BASE_URL,
  headers: headers,
});

export const JSON_CONFIG = {
  headers: { "content-type": "application/json;charset=UTF-8" },
};

export default instance;
