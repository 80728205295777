import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import InfoInframapsModal from "./infoInframapsModal";
import useStyles from "./styles";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../slider";
import SliderMultiRange from "../sliderMultiRange";
import _ from "lodash";
import { RESET_INFRAMAPS } from "../app/actionTypes";
import API from "../app/actions";
import { colorsArray, selectedFlag } from "./../../utils";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Loader from "../Loader";
import { Grid } from "@material-ui/core";

const initialData = [
  {
    name: "",
    data: [],
    type: "scattermapbox",
    lon: [],
    lat: [],
    marker: {
      color: colorsArray[0],
      size: 8,
    },
  },
];

export default function InfraMapPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mapFilteredData, setMapFilteredData] = useState(initialData);
  const [countrySelected, setCountrySelected] = useState({});
  const [markerData, setMarkerData] = useState({});
  const [displayLoader, setDisplayLoader] = useState(true);
  const [openMarkerDialog, setOpenMarkerDialog] = useState(false);

  const {
    countries,
    year,
    multiRange,
    powerPlantsData,
    reservesData,
    selectedPowerReservesIndicators,
    selectedPowerReservesParentId,
  } = useSelector((state) => state.App);

  useEffect(() => {
    setCountrySelected(countries[countries.length - 1]);
  }, [countries[countries.length - 1]]);

  useEffect(() => {
    if (!powerPlantsData.length) {
      dispatch(API.getPowerPlantsData());
    }
    if (!reservesData.length) {
      dispatch(API.getReservesData());
    }

    return () => {
      dispatch({
        type: RESET_INFRAMAPS,
      });
    };
  }, []);

  useEffect(() => {
    if (powerPlantsData.length && reservesData.length) {
      setDisplayLoader(false);
    }
  }, [powerPlantsData, reservesData]);

  useEffect(() => {
    const data =
      selectedPowerReservesParentId === 1 ? powerPlantsData : reservesData;

    if (
      data.length &&
      countries.length &&
      year !== "" &&
      selectedPowerReservesIndicators.length
    ) {
      const MIN = multiRange[0];
      const MAX = multiRange[1];
      let result = [];
      let filteredData = [];
      if (selectedPowerReservesParentId === 1) {
        result = data.filter(
          (item) =>
            item.commissioning_year <= year &&
            item.capacity >= MIN &&
            item.capacity <= MAX
        );
        //result = data.filter(item=>(item.capacity >= MIN && item.capacity <= MAX));
        console.log("result", result);
        selectedPowerReservesIndicators.map((indicator) => {
          countries.map((country) => {
            result.map((item) => {
              if (
                country.id == item.country_id &&
                indicator.id == item.type_id
              ) {
                filteredData.push(item);
              }
            });
          });
        });

        if (filteredData.length) {
          const mapData = _.chain(filteredData)
            .groupBy("type.name")
            .map((value, key, index) => ({
              name: key,
              data: value,
              type: "scattermapbox",
              lon: _.map(value, "lng"),
              lat: _.map(value, "lat"),
              hovertemplate: "%{text}",
              text: _.map(value, "plant_name"),
              marker: {
                color: colorsArray[index],
                size: 8,
              },
            }))
            .value();
          console.log("mapData", mapData);
          setMapFilteredData(mapData);
        } else {
          setMapFilteredData(initialData);
        }
      } else {
        selectedPowerReservesIndicators.map((indicator) => {
          countries.map((country) => {
            data.map((item) => {
              if (
                country.id == item.country_id &&
                indicator.name.toLowerCase() == item.reserve_type.toLowerCase()
              ) {
                filteredData.push(item);
              }
            });
          });
        });

        if (filteredData.length) {
          const mapData = _.chain(filteredData)
            .groupBy("reserve_type")
            .map((value, key, index) => ({
              name: key,
              data: value,
              type: "scattermapbox",
              lon: _.map(value, "lng"),
              lat: _.map(value, "lat"),
              hovertemplate: "%{text}",
              text: _.map(value, "field"),
              marker: {
                color: colorsArray[index],
                size: 8,
              },
            }))
            .value();
          setMapFilteredData(mapData);
        } else {
          setMapFilteredData(initialData);
        }
      }
    }
  }, [countries, year, multiRange, selectedPowerReservesIndicators]);

  const handleMarkerClick = (e) => {
    console.log("e", e);
    const data = e.points[0].data.data[e.points[0].pointIndex];
    console.log("data", data);
    let countryName =
      !_.isEmpty(data) && !!data.reserve_type
        ? data.country
        : data.country.name;
    setMarkerData({ ...data, countryName });
    setOpenMarkerDialog(true);
  };

  const handleMarkerDialogClose = (e) => {
    setOpenMarkerDialog(false);
  };

  return (
    <div
      className={classes.root}
      style={{ width: `${window.innerWidth - 240}px` }}
    >
      <Loader display={displayLoader} />
      {selectedPowerReservesIndicators.length === 0 ||
      countries.length === 0 ? (
        <Grid
          xs={12}
          justify="center"
          alignItems="center"
          container
          item
          className={classes.overLay}
        >
          <Typography variant="h4" component="h4" color="primary">
            {!selectedPowerReservesIndicators.length
              ? "Please Select Indicator"
              : _.isEmpty(countries)
              ? "Please Select Countries"
              : ""}
          </Typography>
        </Grid>
      ) : (
        ""
      )}
      <Plot
        data={mapFilteredData}
        layout={{
          geo: {
            projection: {
              type: "equirectangular",
            },
          },
          legend: {
            x: 0.17,
            y: 0.95,
          },
          title: "Inframaps",
          width: window.screen.width - 70,
          height: window.screen.height - 231,
          font: {
            color: "#000",
          },
          mode: "markers",
          marker: {
            color: "red",
            size: 14,
          },
          dragmode: "zoom",
          mapbox: {
            scale: 4,
            center: {
              lat: 22,
              lon: 115,
            },
            domain: {
              x: [0, 1],
              y: [0, 1],
            },
            style: "light",
            zoom: 2.9,
          },
          margin: {
            r: 0,
            t: 0,
            b: 0,
            l: 0,
            pad: 0,
          },
          paper_bgcolor: "#FFF",
          plot_bgcolor: "#FFF",
          showlegend: true,
        }}
        config={{
          mapboxAccessToken:
            "pk.eyJ1IjoibWVocmVlbmFzdGFmbmFzIiwiYSI6ImNrYTZtczE3MzA4ZG0yeWw3cnczdmh4enYifQ.0gV4wvyM_izuh5wni8ahrw",
        }}
        onClick={handleMarkerClick}
      />

      {React.useMemo(
        () => (
          <Slider year={year} />
        ),
        [year]
      )}
      {React.useMemo(
        () => (
          <SliderMultiRange
            multiRange={multiRange}
            selectedPowerReservesParentId={selectedPowerReservesParentId}
          />
        ),
        [multiRange, selectedPowerReservesParentId]
      )}

      {ReactDOM.createPortal(
        React.useMemo(
          () => (
            <InfoInframapsModal
              countries={countries}
              year={year}
              countrySelected={countrySelected}
              setCountrySelected={setCountrySelected}
              selectedPowerReservesParentId={selectedPowerReservesParentId}
              reservesData={reservesData}
              powerPlantsData={powerPlantsData}
              multiRange={multiRange}
            />
          ),
          // [indicator,year,yearData,seriesData,countrySelected]
          [
            countries,
            year,
            countrySelected,
            selectedPowerReservesParentId,
            reservesData,
            powerPlantsData,
            multiRange,
          ]
        ),
        document.querySelector("body")
      )}
      {!_.isEmpty(markerData) && (
        <Dialog
          onClose={handleMarkerDialogClose}
          open={openMarkerDialog}
          maxWidth={"sm"}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ padding: 0 }}
            onClose={handleMarkerDialogClose}
          >
            <AppBar position="static" className={classes.popOverTitleBar}>
              <Toolbar style={{ minHeight: 50 }}>
                <IconButton
                  className={classes.menuPopOverButton}
                  color="inherit"
                  aria-label="menu"
                >
                  {countries.length ? (
                    <img
                      src={selectedFlag(markerData?.countryName)}
                      alt=""
                      style={{ width: 35, height: 35 }}
                    />
                  ) : (
                    <Typography variant="h6" noWrap style={{ display: "flex" }}>
                      {markerData?.country.name}
                    </Typography>
                  )}
                </IconButton>
                <Typography variant="h6" noWrap style={{ display: "flex" }}>
                  {markerData?.country.name}
                </Typography>
              </Toolbar>
            </AppBar>
          </DialogTitle>
          <DialogContent dividers>
            {selectedPowerReservesParentId == 1 && !_.isEmpty(markerData) && (
              <div className={classes.powerPlantInfoTable}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Name</strong>
                      </TableCell>
                      <TableCell align="left">
                        {markerData.plant_name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell align="left">
                        {markerData.plant_status}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Type</strong>
                      </TableCell>
                      <TableCell align="left">
                        {markerData?.type?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Capacity</strong>
                      </TableCell>
                      <TableCell align="left">
                        {markerData.capacity}{" "}
                        {markerData.country.code === "MV" ? "KW" : "MW"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Commissioning Year</strong>
                      </TableCell>
                      <TableCell align="left">
                        {markerData.commissioning_year}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            )}

            {selectedPowerReservesParentId == 2 && !_.isEmpty(markerData) && (
              <div className={classes.powerPlantInfoTable}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Name</strong>
                      </TableCell>
                      <TableCell align="left">{markerData.field}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Reserve</strong>
                      </TableCell>
                      <TableCell align="left">
                        {markerData.original_recoverable_reserves}{" "}
                        {markerData.unit}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Production</strong>
                      </TableCell>
                      <TableCell align="left">
                        {markerData.cummulative_production} {markerData.unit}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>Reserve Type</strong>
                      </TableCell>
                      <TableCell align="left">
                        {markerData.reserve_type}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleMarkerDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
