import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 240,
    backgroundColor: theme.palette.primary.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  menuList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0,
    "& > li": {
      borderBottom: `1px solid ${theme.palette.divider}`,
      fontSize: 14,
    },
    "& > li.selected, li:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.26)",
    },
    "& .Mui-selected": {
      backgroundColor: " rgba(0, 0, 0, 0.30) !important",
    },
  },
  accordionMainDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  subAccordion: {
    display: "block",
    width: "100%",
    boxShadow: "none",
    backgroundColor: `${theme.palette.primary.dark} !important`,
  },
  summary: {
    margin: 0,
  },
  searchAndMenuCnt: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2, 0),
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    width: "100%",
  },
  white: {
    color: theme.palette.common.white,
  },
  infoIcon: {
    color: theme.palette.common.white,
    position: "absolute",
    right: 8,
    top: 8,
    display: "inline-block",
    zIndex: 2222,
  },
  thePopOver: {
    "& .MuiPaper-root.MuiPopover-paper": {
      width: 250,
      padding: theme.spacing(1),
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  childOfChildName: {
    fontSize: 13,
    whiteSpace: "initial",
  },
  clearAllButton: {
    margin: theme.spacing(0, 2, 2, 2),
    backgroundColor: "#ffc935",
    "&:hover": {
      backgroundColor: "#e1b12e",
    },
  },
}));

export default useStyles;
