import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { Button, Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Highcharts from "highcharts/highstock";
import Icon1 from "./../../resources/images/1st.png";
import Icon2 from "./../../resources/images/2nd.png";
import Icon3 from "./../../resources/images/3rd.png";
import Icon5 from "./../../resources/images/5th.png";
import Icon6 from "./../../resources/images/6th.png";
import Icon7 from "./../../resources/images/7th.png";
import PanelContent0 from "./PanelContent0";
import { useSelector } from "react-redux";
import _, { isEmpty } from "lodash";
import CustomTooltip from "../CustomTooltip";
import { useDispatch } from "react-redux";
import PanelContent1 from "./PanelContent1";
import PanelContent2 from "./PanelContent2";
import PanelContent3 from "./PanelContent3";
import DownloadByIndicator from "./DownloadByIndicator";

require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/offline-exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const panelList = [
  {
    value: 0,
    label: "Countries Map",
  },
  {
    value: 1,
    label: "Line Graph",
  },
  {
    value: 2,
    label: "Filled Line Graph",
  },
  {
    value: 3,
    label: "Bar Graph",
  },
  {
    value: 4,
    label: "Line Matrix Graph",
  },
  {
    value: 5,
    label: "Bubble Graph",
  },
];

const chartData = {
  chart: {
    type: "line",
  },
  credits: { enabled: false },
  title: false,
  yAxis: {
    title: {
      enabled: true,
    },
    visible: true,
    labels: { enabled: true },
  },
  // plotOptions: {
  //   series: {
  //     label: {
  //       // connectorAllowed: false,
  //     },
  //     pointStart: 2000,
  //   },
  // },
  xAxis: {
    labels: { enabled: true },
  },
  exporting:{
    fallbackToExportServer: false,
    accessibility:{
      enabled:true
    },
    enabled:true,
    showTable:false,
    buttons:{
      contextButton:{
        align:'left',
        x:10,
        // menuItems:["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
      }
    }
  }
};

let clickMap = 1;

function TabPanel(props) {
  const { children, value, type, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function VerticalTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [chartType, setChartType] = useState("line");
  const {
    indicator,
    countries,
    multiRangeYear,
      year,
    category,
    subCategory,
    indicatorAPIData } = useSelector(
    (state) => state.App
  );
  const [data, setData] = useState(chartData);
  const dispatch = useDispatch();

  useEffect(() => {

    if (indicatorAPIData.length
        && !isEmpty(countries)
        && !isEmpty(indicator)
        && countries.length
        && !isEmpty(category)
        && !isEmpty(subCategory)
        && value !==0) {

      const array = [];
      let innerObj = [];
      let unit = '';
        countries.map((country) => {
          indicatorAPIData.filter((item) => {
            if (
              category.id === item.category_id &&
              subCategory.child_id === item.subcategory_id &&
              indicator.id === item.indicator_id &&
              country.code === item.code &&
                item.year >= multiRangeYear[0] &&
                item.year <= multiRangeYear[1]) {
              unit = item.unit;
              if (year === item.year) {
                innerObj.push({
                  y: item?.indicator_name.includes('%') ? item.value*100 : item.value,
                  color: "red",
                  name: item?.indicator_name,
                  x:item.year
                });
              } else
                innerObj.push({
                  y: item?.indicator_name.includes('%') ? item.value*100 : item.value,
                  color: "",
                  name: item?.indicator_name,
                  x:item.year
                });
            }
          });

          const innerObjSortedByYear = _.sortBy(innerObj,'x');
          // console.log('innerObjSortedByYear',innerObjSortedByYear);
          array.push({ data: innerObjSortedByYear, name: country.name, unit, tooltip: {valueDecimals: 2}});
          innerObj = [];
        });
        console.log('arrayyyy',array);

      let allCountryDataNotEmpty=false;
      for (var i = 0; i < array.length; i++) {
        if(array[i].data.length !== 0){
          allCountryDataNotEmpty = true;
          break;
        }
      }

      if(!allCountryDataNotEmpty){
        for (var i = 0; i < array.length; i++) {
          array[i].data.push({
            y: 0,
            color: "",
            name: indicator.name,
            x:2000
          });
          array[i].data.push({
            y: 0,
            color: "",
            name: indicator.name,
            x:2020
          })
        }
      }


      console.log('arrayNew',array);
        setData({
          ...data,
          series: array,
          chart: {
            type: chartType,
          },
          // plotOptions:{
          //   series:{
          //     pointStart:multiRangeYear[0]
          //   }
          // }
        });
    }
  }, [indicator, countries, indicatorAPIData, value, year, multiRangeYear]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === 0 && clickMap === 1){
      clickMap = 0;
      setTimeout(()=>{
        document.querySelector('#horizontal-tab-0').click()
      },100)

    } else {
      clickMap = 1;
    }

    switch (newValue) {
      case 1:
        setChartType("line");
        setData({
          ...data,
          chart: {
            type: "line",
          },
        });
        break;
      case 2:
        setChartType("area");
        setData({
          ...data,
          chart: {
            type: "area",
          },
        });
        // code block
        break;
      case 3:
        setChartType("column");
         setData({
          ...data,
          chart: {
            type: "column",
          },
        });
        // code block
        break;
      case 4:
        setChartType("bar");
         setData({
          ...data,
          chart: {
            type: "bar",
          },
        });
        // code block
        break;
      case 5:
        setChartType("packedbubble");
         setData({
          ...data,
          chart: {
            type: "packedbubble",
          },
        });
        // code block
        break;

      default:
        setChartType("line");
         setData({
          ...data,
          chart: {
            type: "line",
          },
        });
      // code block
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.tabsCnt}>
        <Button
            disabled={!indicator.name || !countries.length}
          classes={{ root: classes.resetButton }}
          onClick={() => dispatch({ type: "RESET_CONTENT" })}
        >
          Reset
        </Button>
        <Tabs
          orientation="horizontal"
          variant="standard"
          value={value}
          onChange={handleChange}
          aria-label="Horizontal tabs"
          className={classes.tabs}
        >
          <CustomTooltip
            placement="bottom"
            title="Countries Map"
          >
            <Tab
              icon={<img alt="" src={Icon1} />}
              id="horizontal-tab-0"
              aria-controls="horizontal-tabpanel-0"
              disabled={!indicator.name || !countries.length}
            />
          </CustomTooltip>
          <CustomTooltip placement="bottom" title="Line Graph">
            <Tab
              label={<img alt="" src={Icon2} />}
              id="horizontal-tab-1"
              aria-controls="horizontal-tabpanel-1"
              disabled={!indicator.name || !countries.length}
            />
          </CustomTooltip>
          <CustomTooltip placement="bottom" title="Filled Line Graph">
            <Tab
              label={<img alt="" src={Icon3} />}
              id="horizontal-tab-2"
              aria-controls="horizontal-tabpanel-2"
              disabled={!indicator.name || !countries.length}
            />
          </CustomTooltip>
          <CustomTooltip placement="bottom" title="Bar Graph">
            <Tab
              label={<img alt="" src={Icon5} />}
              id="horizontal-tab-3"
              aria-controls="horizontal-tabpanel-3"
              disabled={!indicator.name || !countries.length}
            />
          </CustomTooltip>
          <CustomTooltip placement="bottom" title="Line Matrix Graph">
            <Tab
              label={<img alt="" src={Icon6} />}
              id="horizontal-tab-4"
              aria-controls="horizontal-tabpanel-4"
              disabled={!indicator.name || !countries.length}
            />
          </CustomTooltip>
          <CustomTooltip placement="bottom" title="Bubble Graph">
            <Tab
              label={<img alt="" src={Icon7} />}
              id="horizontal-tab-5"
              aria-controls="horizontal-tabpanel-5"
              disabled={!indicator.name || !countries.length}
            />
          </CustomTooltip>
        </Tabs>
      </div>
      {panelList.map((val, key) => (
        <TabPanel className={classes.tabPanel} value={value} key={val.value} index={val.value}>
          {!indicator.name || !countries.length ? (
            <Grid
              xs={12}
              justify="center"
              alignItems="center"
              container
              item
              className={classes.overLay}
            >
              <Typography variant="h4" component="h4" color="primary">
                {!indicator.name
                  ? "Please Select Indicator"
                  : !countries.length
                  ? "Please Select Countries"
                  : ""}
              </Typography>
            </Grid>
          ) : (
            ""
          )}
          {value === 0 ? (
              <>
                <DownloadByIndicator/>
                <PanelContent0 />
              </>
          ) : value === 4 ? (
              <>
                <DownloadByIndicator/>
                <PanelContent2 data={data}/>
              </>
          ) : value === 5 ? (
              <>
                <DownloadByIndicator/>
                <PanelContent3/>
              </>
          ) : (
              <>
                <DownloadByIndicator/>
                <PanelContent1 data={data} chartType={value}/>
              </>
          )}
        </TabPanel>
      ))}

    </div>
  );
}
