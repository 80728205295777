import React, {useEffect} from 'react';
import useStyles from "./styles";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from "@material-ui/core/Grid";

export default ({open, handleClose, content, maxWidth}) => {
    const classes = useStyles();

    const splitName=(doc)=>{
        let array = doc.split('/');
        return array[array.length-1];
    }

    return(
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth={maxWidth || 'md'}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {content.title}
                <Typography className={classes.country} component="p">
                    {content.country+' '+content.effective_year}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>

                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        {/*<strong>Draft Year:</strong> {content.year}*/}
                        <strong>Document Type:</strong> {!!content.document_type ? content.document_type:''}
                    </Grid>
                    <Grid item xs={6}>
                        <strong>Effective Start Year:</strong> {content.effective_year}
                    </Grid>
                    <Grid item xs={6}>
                        <strong>Category:</strong> {content.category}
                    </Grid>
                    <Grid item xs={6}>
                        <strong>Issued by:</strong> The Government of {content.country}
                    </Grid>
                    <Grid item xs={12}>
                        <strong> Source:</strong> {content.source}
                    </Grid>
                </Grid><br/>
                <strong>Overall Summary:</strong>
                <Typography variant="body2" component="p" gutterBottom>
                    {content.summary}
                </Typography>

            </DialogContent>
            <DialogActions>
                <a
                    className={classes.downloadButton}
                    href={`http://epadmin.thedabsters.com/storage/${content.document}`}
                    target='_blank'
                    download
                >
                    Download Policy
                </a>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}


