import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const arrowGenerator = (color) => {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.95em",
      width: "2em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.95em",
      width: "2em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.95em",
      height: "2em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.95em",
      height: "2em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
};

const useStylesArrow = makeStyles((theme) => ({
  tooltip: (props) => ({
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    fontSize: props.fontSize,
    boxShadow: `0px 0px 3px 1px ${theme.palette.primary.light}`,
    wordBreak: "break-word",
  }),
  arrow: {
    position: "absolute",
    fontSize: 6,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  popper: arrowGenerator(theme.palette.primary.main),
}));

const ArrowTooltip = (props) => {
  const { arrow, ...classes } = useStylesArrow(props);
  const [arrowRef, setArrowRef] = React.useState(null);
  const noArrow = !!props.noArrow ? props.noArrow : false;
  return (
    <Tooltip
      classes={classes}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
      placement={props.placement ?? "left"}
      {...props}
      title={
        <React.Fragment>
          {!!props.title ? props.title : "No text Available"}
          {!noArrow ? <span className={arrow} ref={setArrowRef} /> : null}
        </React.Fragment>
      }
    />
  );
};

ArrowTooltip.propTypes = {
  title: PropTypes.node,
};

export default ArrowTooltip;
