import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import Menu from "./leftMenu";
import NaturalGas from "./../../resources/images/resourceMaps/naturalGas.png";
import OilReserves from "./../../resources/images/resourceMaps/oilReserves.png";
import HydraulicEngery from "./../../resources/images/resourceMaps/HydraulicEnergyPotentialWorld.gif";
import ProductiveForest from "./../../resources/images/resourceMaps/ProductiveForestAreaWorld.gif";
import PotentialAvailability from "./../../resources/images/resourceMaps/PotentialAvailabilityofSugarCaneBagasse.gif";
import WaveEnergyWorld from "./../../resources/images/resourceMaps/WaveEnergyWorld.jpeg";
import SurfaceOcean from "./../../resources/images/resourceMaps/SurfaceOceanCurrentsWorld.gif";

const listFormat = [
  {
    ind_name: "Resource Maps",
    id: 1,
    child_ind: [
      {
        ind_name: "Solar",
        child_id: 2,
        child_of_child: [
          {
            name: "Solar",
            id: 1,
            selected: true,
            url: "https://globalsolaratlas.info/map?c=22.134155,70.059548,3",
            pChild_id: 2,
          },
        ],
      },
      {
        ind_name: "Wind",
        child_id: 3,
        child_of_child: [
          {
            name: "Wind",
            id: 1,
            selected: false,
            url: "https://globalwindatlas.info/",
            pChild_id: 3,
          },
        ],
      },
      {
        ind_name: "Fossil Fuel Reserves",
        child_id: 4,
        child_of_child: [
          {
            name: "Oil Reserves (Asia)",
            id: 1,
            selected: false,
            image: OilReserves,
            alt: "Oil Reserves",
            details: (
              <>
                This entry is the stock of proved reserves of crude oil in
                barrels (bbl). Proved reserves are those quantities of petroleum
                which, by analysis of geological and engineering data, can be
                estimated with a high degree of confidence to be commercially
                recoverable from a given date forward, from known reservoirs and
                under current economic conditions.
                <br /> Source:{" "}
                <a
                  href="https://www.indexmundi.com/map/?t=0&v=97&r=as&l=en"
                  target="_blank"
                >
                  IndexMundi
                </a>
              </>
            ),
          },
          {
            name: "Natural Gas Reserves (Asia)",
            id: 2,
            selected: false,
            image: NaturalGas,
            alt: "Natural Gas",
            details: (
              <>
                This entry is the stock of proved reserves of natural gas in
                cubic meters (cu m). Proved reserves are those quantities of
                natural gas, which, by analysis of geological and engineering
                data, can be estimated with a high degree of confidence to be
                commercially recoverable from a given date forward, from known
                reservoirs and under current economic conditions.
                <br />
                Source:{" "}
                <a
                  href="https://www.indexmundi.com/map/?t=0&v=98&r=as&l=en"
                  target="_blank"
                >
                  IndexMundi
                </a>
              </>
            ),
          },
        ],
      },
      {
        ind_name: "Hydropower Maps",
        child_id: 5,
        child_of_child: [
          {
            name: "Hydraulic Energy Potential",
            id: 1,
            selected: false,
            image: HydraulicEngery,
            alt: "Hydraulic Energy - Potential World",
            details: (
              <>
                The maps show the theoretical energy potential in relation to
                the country area, in MWh/km²a. The countries having the highest
                relative potential are: Bhutan, Nepal, Tajikistan and
                Switzerland.
                <br />
                Source:{" "}
                <a
                  href="http://www.geni.org/globalenergy/library/renewable-energy-resources/hydropower.shtml"
                  target="_blank"
                >
                  Global Energy Network Institute
                </a>
              </>
            ),
          },
        ],
      },
      {
        ind_name: "Bio-Energy Maps",
        child_id: 6,
        child_of_child: [
          {
            name: "Productive Forest Area",
            id: 1,
            selected: false,
            image: ProductiveForest,
            alt: "Productive Forest Area - World",
            details: (
              <>
                The maps show the fuelwood production in the different
                countries. The values are expressed in (metric) tonnes per
                capita. The energy equivalent of one tonne of wood is roughly
                3.8 MWh.
                <br />
                Source:{" "}
                <a
                  href="http://www.geni.org/globalenergy/library/renewable-energy-resources/bioenergybig.shtml"
                  target="_blank"
                >
                  Global Energy Network Institute
                </a>
              </>
            ),
          },
          {
            name: "Potential Availability of Sugar Cane Bagasse",
            id: 2,
            selected: false,
            image: PotentialAvailability,
            alt: "Potential Availability of Sugar-Cane Bagasse",
            details: (
              <>
                The maps show the availability of sugar cane bagasse in the
                different countries. The values are expressed in (metric) tonnes
                per capita. The energy equivalent of one tonne of bagasse is
                roughly 2.5 MWh. The energy potential reaches an interesting
                level in the sugar producing countries.
                <br />
                Source:{" "}
                <a
                  href="http://www.geni.org/globalenergy/library/renewable-energy-resources/bioenergybig.shtml"
                  target="_blank"
                >
                  Global Energy Network Institute
                </a>
              </>
            ),
          },
        ],
      },
      {
        ind_name: "Ocean Energy",
        child_id: 7,
        child_of_child: [
          {
            name: "Wave Energy",
            id: 1,
            selected: false,
            image: WaveEnergyWorld,
            alt: "Wave Energy - World",
            details: (
              <>
                The wave power density measured in kilowatts (kW) per meter (m)
                of wave front for the various parts of the world are provided in
                this graphic.
                <br />
                Source:{" "}
                <a
                  href="http://www.geni.org/globalenergy/library/renewable-energy-resources/ocean.shtml"
                  target="_blank"
                >
                  Global Energy Network Institute
                </a>
              </>
            ),
          },
          {
            name: "Surface Ocean Currents",
            id: 2,
            selected: false,
            image: SurfaceOcean,
            alt: "Surface Ocean Currents - World",
            details: (
              <>
                Major ocean currents of the world. On this illustration red
                arrows indicate warm currents, while cold currents are displayed
                in blue.
                <br />
                Source:{" "}
                <a
                  href="http://www.geni.org/globalenergy/library/renewable-energy-resources/ocean.shtml"
                  target="_blank"
                >
                  Global Energy Network Institute
                </a>
              </>
            ),
          },
        ],
      },
    ],
  },
];

export default () => {
  const classes = useStyles();
  const [list, setList] = useState(listFormat);
  const [mapItem, setMapItem] = useState(
    list[0].child_ind[0].child_of_child[0]
  );

  const onMenuItemClickHandler = (childIdx, childOfChildIdx) => {
    setMapItem(list[0].child_ind[childIdx].child_of_child[childOfChildIdx]);
    let newList = listFormat;
    newList[0].child_ind[0].child_of_child[0].selected = false;
    newList[0].child_ind[childIdx].child_of_child[
      childOfChildIdx
    ].selected = true;
    setList(newList);
  };

  return (
    <div className={classes.root}>
      <Menu list={list} onMenuItemClickHandler={onMenuItemClickHandler} />
      <div className={classes.imgCnt}>
        {!!mapItem.image && (
          <>
            {!!mapItem.details && (
              <Typography paragraph style={{ padding: "8px" }}>
                {mapItem.details}
              </Typography>
            )}
            <img src={mapItem.image} alt={mapItem.alt} width={"100%"} />
          </>
        )}

        {!!mapItem.url && (
          <iframe
            src={mapItem.url}
            style={{
              width: `${window.innerWidth - 296}px`,
              height: `${window.innerHeight - 125}px`,
              marginLeft: 20,
            }}
            title={mapItem.name}
            frameBorder="0"
          ></iframe>
        )}
      </div>
    </div>
  );
};
