import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    countryToolBar:{
        backgroundColor:'#ffc935',
        color:theme.palette.secondary.contrastText,
        padding:theme.spacing(0),
        height:40,
        minHeight:40,
        overflow:'hidden'
    },
    toggleButtonGroup:{
        '& button':{
            textTransform:'capitalize',
            color:'rgba(0,0,0,0.7)',
            '&:hover':{
                backgroundColor:theme.palette.primary.main,
                color:theme.palette.primary.contrastText,
            },
            '&.Mui-selected':{
                backgroundColor:theme.palette.primary.main,
                color:theme.palette.primary.contrastText,
            }
        }
    }
}));

export default useStyles;