import React from "react";
import useStyles from "./styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="h5"
        component="h1"
        style={{ marginTop: 15, marginBottom: 22 }}
      >
        Frequently Asked Questions
      </Typography>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.summary}
        >
          <Typography className={classes.heading}>
            How can I contribute to the portal?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.faqAccordian}>
          <Typography>
            You can make suggestions what functionalities you would like to see
            on the portal via the ‘Feedback’ tab. Furthermore, you could assist
            SEC in improving its data accuracy by sharing with us latest
            statistics.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.summary}
        >
          <Typography className={classes.heading}>
            Can I use the data freely?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.faqAccordian}>
          <Typography>
            All the data available in this portal is free and can be used for
            any purpose. However, SEC has collected the data from various
            sources and does not take the responsibility of its accuracy. The
            data sources are provided for your consideration.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={classes.summary}
        >
          <Typography className={classes.heading}>
            How can I download the database?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.faqAccordian}>
          <Typography>
            You can download the whole database by going to the ‘Indicators’ tab
            and then selecting the download PDF option from the bottom left of
            the page.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
          className={classes.summary}
        >
          <Typography className={classes.heading}>
            Can I download the plots/figures?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.faqAccordian}>
          <Typography>
            Yes, you can download the plots. For downloading, move the cursor at
            the menu option on top left side of the charts. Click on menu option
            and it display options for download.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
