import React from 'react';
import PolicyTimeLinePage from '../policyTimeLinePage';
import PolicySearchPage from '../policySearchPage';

const PolicyPage = ({match}) => {
    if(match.params.policyId === 'timeline')
        return (<PolicyTimeLinePage/>)
    else
        return (<PolicySearchPage/>)
}


export default PolicyPage;