import React from 'react';
import CalorificImage1 from './../../resources/images/cal-1.jpeg';
import CalorificImage2 from './../../resources/images/cal-2.jpeg';
import useStyles from "./styles";

export default () => {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <img src={CalorificImage1} alt=""/><br/>
            <img src={CalorificImage2} alt=""/>
        </div>
    )
}


