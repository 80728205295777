import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { Button } from "@material-ui/core";
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import {BASE_HOST} from "../../../axios";

export default function DownloadPdf() {
  const classes = useStyles();

  const handleDownloadPdf=()=>{
    // window.location.href = `${BASE_HOST}/storage/saarcdatabook.pdf`;
    window.open(`${BASE_HOST}/storage/saarcdatabook.pdf`);
  }

  return (
    <>
      <Button variant="contained"
              size="small"
              color="secondary"
              className={classes.downloadPDFButton}
              onClick={handleDownloadPdf}
              startIcon={<PictureAsPdf/>}
      >
        Download PDF
      </Button>
    </>
  );
}
