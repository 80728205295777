import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Popover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";
import useStyles from "./styles";
import API from "../app/actions";
import { CLEAR_ALL_COMPARE_INDICATORS } from "./../app/actionTypes";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const LeftMenu = ({ match }) => {
  const classes = useStyles();
  const leftMenuList = useSelector((state) => state.App.leftMenu);
  const { compareIndicators, indicator } = useSelector((state) => state.App);
  const dispatch = useDispatch();
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [copyLeftMenuList, setCopyLeftMenuList] = useState(leftMenuList);
  const [openAccordian, setOpenAccordian] = useState(false);

  //Routes Test for Menu
  const [hasMenu, setMenu] = useState(false);
  const leftMenu = [
    "/data/indicators",
    "/data/energy-trade-flow",
    "/data/compare",
    // "/inframaps",
  ];
  useEffect(() => {
    if (!!leftMenu.includes(match.url)) {
      setMenu(true);
    } else {
      setMenu(false);
    }
  }, [hasMenu, match]);

  //Initial Prop set with local state
  useEffect(() => {
    setCopyLeftMenuList(JSON.parse(JSON.stringify(leftMenuList)));
  }, [leftMenuList]);

  //Search Field Filter
  useEffect(() => {
    if (searchFieldValue.length > 0) {
      setOpenAccordian(true);
      let _childOfChild,
        child = [];
      let itemIndex = [];
      let childIndex = [];
      const result = JSON.parse(JSON.stringify(leftMenuList)).filter(
        (item, index) => {
          child = item?.child_ind.filter((childItem, index) => {
            const childOfChild = childItem?.child_of_child.filter(
              (childOfChildItem, index) => {
                return childOfChildItem?.name
                  ?.toLowerCase()
                  .includes(searchFieldValue.toLowerCase());
              }
            );
            delete childItem.child_of_child;
            childItem.child_of_child = childOfChild;

            if (
              childItem?.ind_name
                ?.toLowerCase()
                .includes(searchFieldValue.toLowerCase())
            ) {
              itemIndex.push(index);
              return item;
            } else if (childOfChild.length) {
              return item;
            }
          });
          delete item.child_ind;
          item.child_ind = child;
          if (
            item?.ind_name
              ?.toLowerCase()
              .includes(searchFieldValue.toLowerCase())
          ) {
            itemIndex.push(index);
            return item;
          } else if (child.length > 0) {
            return item;
          }
        }
      );
      setCopyLeftMenuList(result);
    } else {
      setCopyLeftMenuList(JSON.parse(JSON.stringify(leftMenuList)));
      setOpenAccordian(false);
    }
  }, [searchFieldValue]);

  const [expanded, setExpanded] = useState("");
  const [childExpanded, setChildExpanded] = useState("");
  const handleParentChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleChildChange = (panel) => (event, newExpanded) => {
    setChildExpanded(newExpanded ? panel : false);
  };

  const clearAllIndicatorsHandler = () => {
    dispatch({
      type: CLEAR_ALL_COMPARE_INDICATORS,
    });
  };

  return (
    <>
      {!!hasMenu ? (
        <div className={classes.root}>
          <SimpleBarReact
            style={{ maxHeight: `${window.innerHeight - 100}px` }}
          >
            <div className={classes.searchAndMenuCnt}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  name="searchField"
                  placeholder="Search indicator"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => {
                    setSearchFieldValue(e.target.value);
                  }}
                  value={searchFieldValue}
                  autoComplete="off"
                />
              </div>
              {compareIndicators.length > 0 && (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={clearAllIndicatorsHandler}
                  className={classes.clearAllButton}
                >
                  Clear All Indicators
                </Button>
              )}
              {!!copyLeftMenuList &&
                copyLeftMenuList.map((ind) => {
                  return (
                    <Accordion
                      key={ind.id}
                      expanded={openAccordian || expanded === ind.id}
                      onChange={handleParentChange(ind.id)}
                    >
                      <AccordionSummary
                        classes={{ root: classes.summary }}
                        expandIcon={
                          <ExpandMoreIcon className={classes.white} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          {ind.ind_name}
                        </Typography>
                      </AccordionSummary>
                      {ind.child_ind.length > 0 &&
                        ind.child_ind.map((child_ind, index) => (
                          <AccordionDetails
                            key={`child_${child_ind.child_id}${index}`}
                            className={classes.accordionMainDetails}
                          >
                            <Accordion
                              className={classes.subAccordion}
                              expanded={
                                openAccordian ||
                                childExpanded === child_ind.child_id
                              }
                              onChange={handleChildChange(child_ind.child_id)}
                            >
                              <AccordionSummary
                                className={classes.summary}
                                expandIcon={
                                  <ExpandMoreIcon className={classes.white} />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography className={classes.heading}>
                                  {child_ind.ind_name}
                                </Typography>
                              </AccordionSummary>
                              {child_ind.child_of_child.length > 0 &&
                                child_ind.child_of_child.map(
                                  (child_of_child, index) => {
                                    if (child_of_child.ind_name === undefined) {
                                      return (
                                        <AccordionDetails
                                          key={`childOfChild_${child_of_child.id}${index}`}
                                        >
                                          <MenuList
                                            className={classes.menuList}
                                          >
                                            {/*<MenuItem className="selected">*/}
                                            <MenuItem
                                              onClick={() => {
                                                dispatch(
                                                  API.mapCategoryState(
                                                    ind,
                                                    child_ind,
                                                    child_of_child,
                                                    match.url,
                                                    compareIndicators
                                                  )
                                                );
                                              }}
                                              className={
                                                match.url === "/data/compare"
                                                  ? compareIndicators.some(
                                                      (value) =>
                                                        value.id ===
                                                        child_of_child.id
                                                    )
                                                    ? "Mui-selected"
                                                    : ""
                                                  : indicator.id ===
                                                    child_of_child.id
                                                  ? "Mui-selected"
                                                  : ""
                                              }
                                            >
                                              <Typography
                                                variant="inherit"
                                                className={
                                                  classes.childOfChildName
                                                }
                                              >
                                                {child_of_child.name}
                                              </Typography>
                                              <ListItemIcon>
                                                <HtmlTooltip
                                                  title={
                                                    <>
                                                      <Typography
                                                        color="primary"
                                                        variant="subtitle2"
                                                      >
                                                        Source
                                                      </Typography>
                                                      <Typography variant="body2">
                                                        {child_of_child.source}
                                                      </Typography>
                                                      <Divider
                                                        className={
                                                          classes.divider
                                                        }
                                                      />
                                                      <Typography
                                                        color="primary"
                                                        variant="subtitle2"
                                                      >
                                                        Description
                                                      </Typography>
                                                      <Typography variant="body2">
                                                        {
                                                          child_of_child.definition
                                                        }
                                                      </Typography>
                                                    </>
                                                  }
                                                  placement="right"
                                                >
                                                  <InfoIcon
                                                    fontSize="small"
                                                    className={classes.infoIcon}
                                                  />
                                                </HtmlTooltip>
                                              </ListItemIcon>
                                            </MenuItem>
                                          </MenuList>
                                        </AccordionDetails>
                                      );
                                    } else {
                                      return (
                                        <AccordionDetails
                                          key={`childOfChild_${child_of_child.id}${index}`}
                                        >
                                          <Accordion
                                            className={classes.subAccordion}
                                            // expanded={
                                            //   openAccordian ||
                                            //   childExpanded === child_ind.child_id
                                            // }
                                            // onChange={handleChildChange(child_ind.child_id)}
                                          >
                                            <AccordionSummary
                                              className={classes.summary}
                                              expandIcon={
                                                <ExpandMoreIcon
                                                  className={classes.white}
                                                />
                                              }
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                            >
                                              <Typography
                                                className={classes.heading}
                                              >
                                                {child_of_child.ind_name}
                                              </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                              key={`childOfChild_${child_of_child.id}${index}`}
                                            >
                                              <MenuList
                                                className={classes.menuList}
                                              >
                                                {child_of_child
                                                  .child_of_child_of_child
                                                  .length > 0 &&
                                                  child_of_child.child_of_child_of_child.map(
                                                    (cococ, index) => {
                                                      return (
                                                        <MenuItem
                                                          onClick={() => {
                                                            console.log(
                                                              "aaaa",
                                                              ind,
                                                              child_ind,
                                                              child_of_child,
                                                              match.url,
                                                              compareIndicators
                                                            );
                                                            dispatch(
                                                              API.mapCategoryState(
                                                                ind,
                                                                child_ind,
                                                                child_of_child,
                                                                match.url,
                                                                compareIndicators,
                                                                cococ
                                                              )
                                                            );
                                                          }}
                                                          className={
                                                            match.url ===
                                                            "/data/compare"
                                                              ? compareIndicators.some(
                                                                  (value) =>
                                                                    value.id ===
                                                                    cococ.id
                                                                )
                                                                ? "Mui-selected"
                                                                : ""
                                                              : indicator.id ===
                                                                cococ.id
                                                              ? "Mui-selected"
                                                              : ""
                                                          }
                                                        >
                                                          <Typography
                                                            variant="inherit"
                                                            className={
                                                              classes.childOfChildName
                                                            }
                                                          >
                                                            {cococ.name}
                                                          </Typography>
                                                          <ListItemIcon>
                                                            <HtmlTooltip
                                                              title={
                                                                <>
                                                                  <Typography
                                                                    color="primary"
                                                                    variant="subtitle2"
                                                                  >
                                                                    Source
                                                                  </Typography>
                                                                  <Typography variant="body2">
                                                                    {
                                                                      cococ.source
                                                                    }
                                                                  </Typography>
                                                                  <Divider
                                                                    className={
                                                                      classes.divider
                                                                    }
                                                                  />
                                                                  <Typography
                                                                    color="primary"
                                                                    variant="subtitle2"
                                                                  >
                                                                    Description
                                                                  </Typography>
                                                                  <Typography variant="body2">
                                                                    {
                                                                      cococ.definition
                                                                    }
                                                                  </Typography>
                                                                </>
                                                              }
                                                              placement="right"
                                                            >
                                                              <InfoIcon
                                                                fontSize="small"
                                                                className={
                                                                  classes.infoIcon
                                                                }
                                                              />
                                                            </HtmlTooltip>
                                                          </ListItemIcon>
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                              </MenuList>
                                            </AccordionDetails>
                                          </Accordion>
                                        </AccordionDetails>
                                      );
                                    }
                                  }
                                )}
                            </Accordion>
                          </AccordionDetails>
                        ))}
                    </Accordion>
                  );
                })}
            </div>
          </SimpleBarReact>
        </div>
      ) : null}
    </>
  );
};

export default LeftMenu;
