import React, { useRef, useState, useEffect } from "react";
import useStyles from "./styles";
import Fab from "@material-ui/core/Fab";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import { Paper, Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import FlagIcon from "@material-ui/icons/Flag";
import Typography from "@material-ui/core/Typography";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useDispatch } from "react-redux";
import { numberWithCommas } from "../../resources/helper/helper";
import { selectedFlag } from "./../../utils";
import _, { isEmpty } from "lodash";
import {numberWithCommaAndRound} from './../../resources/helper/helper';

const chartData = {
  chart: {
    type: "line",
  },
  credits: { enabled: false },
  exporting: {
      fallbackToExportServer: false,
    enabled: false,
  },
  title: false,
  yAxis: {
    title: {
      enabled: false,
    },
    visible: true,
    labels: { enabled: true },
  },
  plotOptions: {
    series: {
      label: {
        // connectorAllowed: false,
      },
      pointStart: 2000,
    },
  },
  xAxis: {
    labels: { enabled: true },
      allowDecimals:false
  },
  legend: {
    enabled: false,
  }
};

export default ({
                    // category,
                  subCategory,
                  indicator,
                  countries,
                  year,
                  yearData,
                  seriesData,
                  countrySelected,
                  setCountrySelected,
                    chartRef}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(true);

  const handleInfoModalClick = () => {
    setOpenModal(!openModal);
  };

  const getSeries = () => {
      console.log('seriesData',seriesData);

      const seriesDataSortedByYear = _.sortBy(seriesData,'x');
      console.log('seriesDataSortedByYear',seriesDataSortedByYear);

    const series = [{ name: indicator.name, data: seriesDataSortedByYear , tooltip: {
            // valueSuffix: '',
            valueDecimals: 2,
        }}];
    return series;
  };
    if(!_.isEmpty(countrySelected) && !_.isEmpty(indicator)){
   return (
    <>
      <Fab
        color="primary"
        size="medium"
        aria-label="Details"
        className={classes.fabIcon}
        onClick={handleInfoModalClick}
      >
        <MyLocationIcon />
      </Fab>
      <Paper
        elevation={1}
        className={`${classes.infoModal} ${openModal ? "open" : null}`}
      >
        <Grid xs={12} container style={{ position: "relative" }}>
          <div className={classes.flagContainer}>
            {countries.length>0 && countries.map((value, idx) => (
                <img
                    src={selectedFlag(value.code)}
                    alt={value.name}
                    key={idx}
                    onClick={() => setCountrySelected(value)}
                />
            ))}
          </div>
          <AppBar position="static" className={classes.popOverTitleBar}>
            <Toolbar>
              <IconButton
                  className={classes.menuPopOverButton}
                  color="inherit"
                  aria-label="menu"
              >
                {countries.length>0 ? (
                    <img
                        src={selectedFlag(countrySelected?.code)}
                        alt=""
                        style={{ width: 35, height: 35 }}
                    />
                ) : (
                    <Typography variant="h6" noWrap style={{ display: "flex" }}>
                        Please select country
                    </Typography>
                )}
              </IconButton>
              <Typography variant="h6" noWrap style={{ display: "flex" }}>
                {countrySelected?.name}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.popOverContent}>
            <Typography variant="subtitle1">
              {subCategory.ind_name} - {indicator.name}
            </Typography>
            <Typography variant="subtitle2">YEAR - {year}</Typography>
            <Typography variant="body2">
              {isEmpty(yearData) ? (
                  "Data not available for this year"
              ) : (
                  <>
                    {(!!yearData.value && indicator.name.includes('%')) ?  numberWithCommaAndRound(yearData.value*100): numberWithCommaAndRound(yearData.value)}&nbsp;
                    {yearData.unit}
                  </>
              )}
            </Typography>
          </div>
          <Grid className={classes.chartCnt} container xs={12}>
            <HighchartsReact
                oneToOne={true}
                ref={chartRef}
                highcharts={Highcharts}
                options={{
                  ...chartData,
                  series: getSeries(),
                }}
                containerProps={{
                  style: {
                    height: 250,
                    width: "100%",
                  },
                }}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )} else { return '';}

};
