import {makeStyles, withStyles, useTheme} from "@material-ui/core/styles";
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
    root:{
        width:'60%',
        padding:theme.spacing(0,5),
        position:'absolute',
        left:'20%',
        bottom:0,
        zIndex:5
    }

}));

export default useStyles;

export const CustomSlider = withStyles({
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -10,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    root: {
        color: '#009688',
        height: 2,
        padding: '15px 0',
    },
    active: {},
    valueLabel: {
        left: -5,
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        backgroundColor: '#009688',
    },
    mark: {
        backgroundColor: '#009688',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: '#009688',
    },
})(Slider);