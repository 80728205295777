import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CountriesMap from "./countriesMap";
import SankeyGraph from "./sankeyGraph";
import API from "../app/actions";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import CustomTooltip from "../CustomTooltip";
import Icon4 from "./../../resources/images/1st.png";
import Icon8 from "./../../resources/images/8th.png";
import Footer from "../footer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

const DataEnergyTradeFlowPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [displayLoader, setDisplayLoader] = useState(true);
  const { tradeFlowAPIData, year } = useSelector((state) => state.App);
  const [value, setValue] = React.useState(0);
  const [resetDataFlag, setResetDataFlag] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!tradeFlowAPIData.length) {
      dispatch(API.getTradeFlowApiData());
    }
    dispatch({
      type: "SET_EXCLUSIVE_COUNTRY",
      payload: true,
    });

    return () => {
      dispatch({
        type: "SET_EXCLUSIVE_COUNTRY",
        payload: false,
      });
    };
  }, []);

  useEffect(() => {
    if (tradeFlowAPIData.length) {
      setDisplayLoader(false);
    }
  }, [tradeFlowAPIData]);

  return (
    <div className={classes.tradeFlowPage}>
      <div className={classes.tabsCnt}>
        <Button
          className={classes.resetButton}
          onClick={() => {
            dispatch({ type: "RESET_CONTENT" });
            setResetDataFlag(true);
          }}
        >
          Reset
        </Button>
        <Tabs
          orientation="horizontal"
          variant="standard"
          value={value}
          onChange={handleChange}
          aria-label="Horizontal tabs"
          className={classes.tabs}
        >
          <CustomTooltip placement="bottom" title="Countries Map">
            <Tab
              label={<img alt="" src={Icon4} />}
              id="horizontal-tab-0"
              aria-controls="horizontal-tabpanel-0"
            />
          </CustomTooltip>
          <CustomTooltip placement="bottom" title="Sankey Graph">
            <Tab
              label={<img alt="" src={Icon8} />}
              id="horizontal-tab-1"
              aria-controls="horizontal-tabpanel-1"
            />
          </CustomTooltip>
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <CountriesMap resetDataFlag={resetDataFlag} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SankeyGraph resetDataFlag={resetDataFlag} />
      </TabPanel>
      <Footer />
    </div>
  );
};

export default DataEnergyTradeFlowPage;
