import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fabIcon: {
    margin: theme.spacing(2),
    borderRadius: 5,
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "#ffc935",
  },
  popOverTitleBar: {
    "& .MuiToolbar-regular": {
      minHeight: 48,
      display: "flex",
      justifyContent: "center",
    },
  },
  menuPopOverButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  popOverContent: {
    padding: theme.spacing(2,0,2,0),
    width:'100%'
  },
  infoModal: {
    backgroundColor: theme.palette.common.white,
    right: 16,
    bottom: 110,
    width: 300,
    position: "absolute",
    display: "none",
    "&.open": {
      display: "block",
    },
  },
  chartCnt: {
    marginTop: 5,
    textAlign:'center'
    // overflow:'hidden',
    // "& > div":{
    //   left:'-33%'
    // }
  },
  flagContainer: {
    position: "absolute",
    left: -30,
    width:25,
    "& img":{
      width:25,
      height:25,
      display:'block',
      marginBottom:5,
      cursor:'pointer'
    }
  },
  powerPlantInfoTable:{
    display:'block',
    width:'100%'
  },
  table:{
    width:"100%"
  }
}));

export default useStyles;
