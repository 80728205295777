import React, { useEffect, useState } from "react";
import _ from "lodash";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import useStyles from "./styles";

const LeftMenuInfraMap = ({ match, list, onMenuItemClickHandler }) => {
  const classes = useStyles();
  const [openAccordian, setOpenAccordian] = useState(false);
  const [expanded, setExpanded] = useState("");
  const [childExpanded, setChildExpanded] = useState("");

  const handleParentChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleChildChange = (panel) => (event, newExpanded) => {
    setChildExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.searchAndMenuCnt}>
          {!!list &&
            list.map((value, key) => (
              <Accordion
                key={key}
                // expanded={openAccordian || expanded === value.id}
                expanded={true}
                onChange={handleParentChange(value.id)}
              >
                <AccordionSummary
                  classes={{ root: classes.summary }}
                  style={{ display: "none" }}
                  // expandIcon={<ExpandMoreIcon className={classes.white} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {value.ind_name}
                  </Typography>
                </AccordionSummary>
                {value.child_ind.map((child, idx) => (
                  <AccordionDetails
                    key={idx}
                    className={classes.accordionMainDetails}
                  >
                    <Accordion
                      className={classes.subAccordion}
                      expanded={
                        openAccordian || childExpanded === child.child_id
                      }
                      onChange={handleChildChange(child.child_id)}
                    >
                      <AccordionSummary
                        className={classes.summary}
                        expandIcon={
                          <ExpandMoreIcon className={classes.white} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          {child.ind_name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MenuList className={classes.menuList}>
                          {child.child_of_child.map((childOfChild, index) => (
                            <MenuItem
                              key={`${childOfChild.name}${index}`}
                              className={
                                childOfChild.selected ? "Mui-selected" : ""
                              }
                              onClick={(event) => {
                                onMenuItemClickHandler(idx, index);
                              }}
                            >
                              <Typography
                                variant="inherit"
                                className={classes.childOfChildName}
                              >
                                {childOfChild.name}
                              </Typography>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                ))}
              </Accordion>
            ))}
        </div>
      </div>
    </>
  );
};

export default LeftMenuInfraMap;
