import {makeStyles} from "@material-ui/core/styles";

export const useMenuStyles = makeStyles((theme) => ({
    root:{
        width:240,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#009688'
    },
    summary:{
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.common.white,
        // '&:hover':{
        //   background:theme.palette.primary.dark
        // },
        '& .MuiAccordionSummary-content':{
            margin:'12px 0'
        },
        '& .MuiAccordionSummary-expandIcon':{
            color:theme.palette.common.white
        }
    },
    accordionMainDetails: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor:'#00796b',
        color:theme.palette.common.white
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    menuList: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingTop: 0,
        paddingBottom: 0,
        "& > li": {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        "& > li.selected, li:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.26)",
        },
        "& .Mui-selected": {
            backgroundColor: " rgba(0, 0, 0, 0.30) !important",
        },
    },

}));

const useStyles = makeStyles((theme) => ({
    root:{
        padding:theme.spacing(0,2,2,0),
        display:'flex',
        height:'calc(100vh - 90px)',
        flexDirection:'row'
    },
    paper:{
        padding:theme.spacing(1),
        background:"#eeeeee",
        marginBottom:theme.spacing(2),
        transition: theme.transitions.create(['background','border']),
        "&:hover":{
            border:`${theme.palette.primary.main} 1px solid`,
            background:"white"
        }
    },
    title: {
        // fontSize:16,
        fontWeight:400,
        "& a":{
            color:'#009688',
            "&:hover":{
                textDecoration:"none"
            }
        }
    },
    summary:{
        textAlign:'left'
    },
    itemTitle:{
        textAlign:'left',
        color:'#009688',
    },
    timeLineCnt:{
        flex:1
    }
}));

export default useStyles;