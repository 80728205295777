export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function numberWithCommaAndRound(n) {
  var parts = (+n).toFixed(2).split(".");
  var num =
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (+parts[1] ? "." + parts[1] : "");
  return num;
}
