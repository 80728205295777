import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root:{
        flex:'1',
        height:'calc(100vh - 90px)',
        position:'relative'
    }

}));

export default useStyles;