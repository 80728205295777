import React from 'react';
import {useMenuStyles} from "./styles";
import Typography from '@material-ui/core/Typography';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";

export default ({categories,onMenuItemClickHandler}) => {
    const classes = useMenuStyles();
    return (
        <div className={classes.root}>
            <Accordion expanded>
                <AccordionSummary
                    classes={{root: classes.summary}}
                    style={{display:'none'}}
                    aria-controls="panel1a-content"
                    // expandIcon={<ExpandMoreIcon className={classes.white} />}
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>
                        Solar
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionMainDetails}
                >
                    <MenuList className={classes.menuList}>
                        {!!categories && categories.map((item,idx)=>(
                            <>
                                <MenuItem
                                    value={item.id}
                                    key={item.id}
                                    onClick={onMenuItemClickHandler}
                                    className={(item.selected) ? "Mui-selected" : ""}
                                >
                                    {item.name}
                                </MenuItem>
                                <Divider/>
                            </>
                        ))}

                        {/*<MenuItem*/}
                        {/*    value={1}*/}
                        {/*    onClick={onMenuItemClickHandler}*/}
                        {/*    className={(!!list && list[1].selected) ? "Mui-selected" : ""}*/}
                        {/*>*/}
                        {/*        Global Horizontal Irradiation*/}
                        {/*</MenuItem>*/}
                        {/*<Divider/>*/}
                        {/*<MenuItem*/}
                        {/*    value={2}*/}
                        {/*    onClick={onMenuItemClickHandler}*/}
                        {/*    className={(!!list && list[2].selected) ? "Mui-selected" : ""}*/}
                        {/*>*/}
                        {/*        Photovoltaic Power Potential*/}
                        {/*</MenuItem>*/}
                    </MenuList>
                </AccordionDetails>
            </Accordion>
            {/*<Accordion expanded>*/}
            {/*    <AccordionSummary*/}
            {/*        classes={{root: classes.summary}}*/}
            {/*        style={{cursor:'default'}}*/}
            {/*        aria-controls="panel2a-content"*/}
            {/*        id="panel2a-header"*/}
            {/*        // expandIcon={<ExpandMoreIcon className={classes.white} />}*/}
            {/*    >*/}
            {/*        <Typography className={classes.heading}>*/}
            {/*            Wind*/}
            {/*        </Typography>*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails*/}
            {/*        className={classes.accordionMainDetails}*/}
            {/*    >*/}
            {/*        <MenuList className={classes.menuList}>*/}
            {/*            <MenuItem*/}
            {/*                value={3}*/}
            {/*                onClick={onMenuItemClickHandler}*/}
            {/*                className={(!!list && list[3].selected) ? "Mui-selected" : ""}*/}
            {/*            >*/}
            {/*                    South Asia*/}
            {/*            </MenuItem>*/}
            {/*        </MenuList>*/}
            {/*    </AccordionDetails>*/}
            {/*</Accordion>*/}
        </div>
    )
}


