import React from "react";
import useStyles from "./styles";
import { Container } from "@material-ui/core";

const Footer = () => {
  const classes = useStyles();

  return (
    <Container disableGutters className={classes.root}>
      {"Developed by "}
      <a href="https://www.thedabsters.com/" target="_blank">
        The Dabsters
      </a>
      {" and "}
      <a href="https://www.diceanalytics.pk/" target="_blank">
        Dice Analytics
      </a>
    </Container>
  );
};

export default Footer;
