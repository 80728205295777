import instance, { BASE_URL } from "./../../axios";
import _ from "lodash";

import {
  GET_COUNTRIES_LIST,
  GET_LEFT_MENU,
  GET_INDICATOR_API_DATA,
  SET_YEAR_FOR_MAP,
  SET_MULTI_RANGE_INFRAMAP,
  GET_POWER_PLANTS_DATA,
  GET_RESERVES_DATA,
  SET_SELECTED_POWER_RESERVES_INDICATORS,
  SET_SELECTED_TRADE_FLOW_INDICATORS,
  GET_TRADE_FLOW_API_DATA,
  SET_MULTI_RANGE_YEAR,
  ALL_SUBCATEGORIES,
} from "./actionTypes";

export const getCountriesList = () => (dispatch) => {
  instance
    .get(`${BASE_URL}/countries`)
    .then(
      (success) => {
        let sortedData = _.orderBy(success.data, "name", "asc");
        dispatch({
          type: GET_COUNTRIES_LIST,
          payload: sortedData,
        });
      },
      (failure) => {
        //console.log(failure);
      }
    )
    .catch((err) => {
      // console.log(err);
    });
};

export const getLeftMenu = () => (dispatch) => {
  instance
    .get(BASE_URL + "/indicators/list")
    .then(
      (success) => {
        dispatch({
          type: GET_LEFT_MENU,
          payload: success.data,
        });
      },
      (failure) => {
        //  console.log(failure);
      }
    )
    .catch((error) => {
      //  console.log("getLeftMenu Error", error);
    });
};

export const getIndicatorApiData = () => (dispatch) => {
  instance
    .get(BASE_URL + "/indicators")
    .then(
      (success) => {
        dispatch({
          type: GET_INDICATOR_API_DATA,
          payload: success.data,
        });
        let subCats = [];
        success.data.map((item) => {
          let found = false;
          if (subCats.length > 0) {
            for (let i = 0; i < subCats.length; i++) {
              if (subCats[i].subcategory_id == item.subcategory_id) {
                found = true;
                break;
              }
            }
          }

          if (!found)
            subCats.push({
              subcategory: item.subcategory,
              subcategory_id: item.subcategory_id,
            });
        });

        dispatch({
          type: ALL_SUBCATEGORIES,
          payload: subCats,
        });

        //   const result = success.data.filter(item=>{
        //
        //       return (item.category_id === 15 && item.subcategory_id === 42)
        //   });
        // console.log('result',result);
      },
      (failure) => {
        // console.log(failure);
      }
    )
    .catch((error) => {
      // console.log("get Indicator API Error", error);
    });
};

export const mapCategoryState =
  (
    category,
    subCategory,
    indicator,
    location,
    compareIndicator,
    cococ = null
  ) =>
  (dispatch) => {
    if (location === "/data/compare") {
      let array = [...compareIndicator];
      let a = {};
      array.map((value, idx) => {
        if (value.id === indicator.id) {
          a = { value, idx };
        }
      });

      if (!!a && a.value) {
        array.splice(a.idx, 1);
      } else {
        array.push({
          ...indicator,
          subCategoryId: subCategory.child_id,
          categoryId: category.id,
        });
      }
      dispatch({
        type: "SET_INDICATORS_VALUE_COMPARE",
        payload: array,
      });
    } else
      dispatch({
        type: "SET_CATEGORIES_FOR_MAP",
        payload: {
          category,
          subCategory,
          indicator,
          cococ,
        },
      });
  };

export const setYearForMap = (newValue) => (dispatch) => {
  dispatch({
    type: SET_YEAR_FOR_MAP,
    payload: newValue,
  });
};

export const setMultiRangeMap = (newValue) => (dispatch) => {
  dispatch({
    type: SET_MULTI_RANGE_INFRAMAP,
    payload: newValue,
  });
};

export const setMultiRangeYearMap = (newValue) => (dispatch) => {
  dispatch({
    type: SET_MULTI_RANGE_YEAR,
    payload: newValue,
  });
};

export const getPowerPlantsData = () => (dispatch) => {
  instance
    .get(BASE_URL + "/powerplants")
    .then(
      (success) => {
        dispatch({
          type: GET_POWER_PLANTS_DATA,
          payload: success.data,
        });
      },
      (failure) => {
        //  console.log(failure);
      }
    )
    .catch((error) => {
      //  console.log("getLeftMenu Error", error);
    });
};

export const getReservesData = () => (dispatch) => {
  instance
    .get(BASE_URL + "/reserves")
    .then(
      (success) => {
        dispatch({
          type: GET_RESERVES_DATA,
          payload: success.data,
        });
      },
      (failure) => {
        //  console.log(failure);
      }
    )
    .catch((error) => {
      //  console.log("getLeftMenu Error", error);
    });
};

export const setSelectedPowerReservesIndicators =
  (indicator, parentId, add) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_POWER_RESERVES_INDICATORS,
      payload: { indicator, parentId, add },
    });
  };

export const setSelectedTradeFlowIndicators =
  (indicator, parentId, add) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_TRADE_FLOW_INDICATORS,
      payload: { indicator, parentId, add },
    });
  };

export const getTradeFlowApiData = () => (dispatch) => {
  instance
    .get(BASE_URL + "/tradeflows/all")
    .then(
      (success) => {
        dispatch({
          type: GET_TRADE_FLOW_API_DATA,
          payload: success.data,
        });
      },
      (failure) => {
        // console.log(failure);
      }
    )
    .catch((error) => {
      // console.log("get Indicator API Error", error);
    });
};

export default {
  getLeftMenu,
  getIndicatorApiData,
  mapCategoryState,
  getCountriesList,
  setYearForMap,
  setMultiRangeMap,
  getPowerPlantsData,
  getReservesData,
  setSelectedPowerReservesIndicators,
  setSelectedTradeFlowIndicators,
  getTradeFlowApiData,
  setMultiRangeYearMap,
};
