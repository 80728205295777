import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root:{
        display:'flex',
        flexDirection:'row',
        height:'calc(100% - 90px)'
    },
    grow:{
        flex:1
    },
    content:{
        paddingTop:theme.spacing(0),
        paddingLeft:theme.spacing(0),
    }

}));

export default useStyles;