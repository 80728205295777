import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";
import Fab from "@material-ui/core/Fab";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import { Paper, Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useDispatch } from "react-redux";
import { selectedFlag } from "./../../../utils";
import _ from "lodash";
import Plot from "react-plotly.js";

export default ({
    countries,
    year,
    countrySelected,
    setCountrySelected,
    selectedPowerReservesParentId,
    powerPlantsData,
    reservesData,
    multiRange
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
    const chartRef = useRef();
  const [openModal, setOpenModal] = useState(true);
  const [pieFilteredData, setPieFilteredData] = useState([]);

  const handleInfoModalClick = () => {
    setOpenModal(!openModal);
  };

  useEffect(()=>{

    const data = selectedPowerReservesParentId === 2 ? reservesData : powerPlantsData;

    if (data.length && countries.length && !_.isEmpty(countrySelected)) {
        let aggregatePowerLabels=[];
        let aggregatePowerValues=[];
        let aggregatePowerObj={};

      if(selectedPowerReservesParentId == 2){
          let uniqType = _.uniqBy(data, 'reserve_type');
          let uniqTypeNameList = _.map(uniqType,'reserve_type');
          _.forEach(uniqTypeNameList, function(value, key) {
              aggregatePowerObj[value] = _.sumBy(data, function(o) {
                  return (o.country_id == countrySelected.id && o.reserve_type === value && o.cummulative_production !== null) ? parseFloat(o.cummulative_production) : 0;
              });
          });
      }
      else {
        let uniqType = _.uniqBy(data, 'type_id');
        let uniqTypeResult = uniqType.map(item=>{
          return item.type
        });
        let uniqTypeNameList = _.map(uniqTypeResult,'name');
          _.forEach(uniqTypeNameList, function(value, key) {
              aggregatePowerObj[value] = _.sumBy(data, function(o) {
                  return (o.country_id == countrySelected.id && o.type.name === value && o.commissioning_year <= year && (o.capacity >= multiRange[0] && o.capacity <= multiRange[1] ) ) ? o.capacity : 0;
              });
          });
      }

        let filterNonZero = {};
        _.forOwn(aggregatePowerObj, function(value, key) {
            if(value != 0){
                filterNonZero[key]=value
            }
        } );

        _.forEach(filterNonZero, function(value, key){
            aggregatePowerLabels.push(key);
            aggregatePowerValues.push(value);
        });

        const powerValuesTotal = _.sum(aggregatePowerValues, function(object) {
            return object.n;
        });


        const totalOfValues = _.sum(aggregatePowerValues);
        let newLabelsArray = [];
        let newValuesArray=[];

        let indexToSuplice = [];
        const filterOtherValues = aggregatePowerValues.filter((item,index)=>{
            let result = (item/totalOfValues)*100;
            if(result<=1) {
                indexToSuplice.push(index);
                return true;
            }
            else {
                newLabelsArray.push(aggregatePowerLabels[index]);
                newValuesArray.push(aggregatePowerValues[index]);
                return false;
            }
        });

        let resultArray=[];
        if(filterOtherValues.length==0){
             resultArray = [{
                insidetextorientation: "radial",
                type: "pie",
                labels:newLabelsArray,
                values:newValuesArray
            }]
        } else {
             resultArray = [{
                insidetextorientation: "radial",
                type: "pie",
                labels:[...newLabelsArray, 'Others'],
                values:[...newValuesArray, _.sum(filterOtherValues)]
            }]
        }


        console.log('resultArray',resultArray);


        // const resultArray = [{
        //     insidetextorientation: "radial",
        //     type: "pie",
        //     labels:aggregatePowerLabels,
        //     values:aggregatePowerValues
        // }]

        setPieFilteredData(resultArray);

    } else{
        if(countries.length == 0 && data.length && selectedPowerReservesParentId == null){

            let aggregatePowerLabels=[];
            let aggregatePowerValues=[];
            let aggregatePowerObj={};
            let uniqType = _.uniqBy(data, 'type_id');

            let uniqTypeResult = uniqType.map(item=>{
                return item.type
            });

            let uniqTypeNameList = _.map(uniqTypeResult,'name');

            _.forEach(uniqTypeNameList, function(value, key) {
                aggregatePowerObj[value] = _.sumBy(data, function(o) {
                    return (o.type.name === value && o.commissioning_year <= year) ? o.capacity : 0;
                });
            });

            let filterNonZero = {};
            _.forOwn(aggregatePowerObj, function(value, key) {
                if(value != 0){
                    filterNonZero[key]=value
                }
            } );

            _.forEach(filterNonZero, function(value, key){
                aggregatePowerLabels.push(key);
                aggregatePowerValues.push(value);
            });

            const powerValuesTotal = _.sum(aggregatePowerValues, function(object) {
                return object.n;
            });

            const totalOfValues = _.sum(aggregatePowerValues);
            let newLabelsArray = [];
            let newValuesArray=[];

            let indexToSuplice = [];
            const filterOtherValues = aggregatePowerValues.filter((item,index)=>{
                let result = (item/totalOfValues)*100;
                if(result<=1) {
                    indexToSuplice.push(index);
                    return true;
                }
                else {
                    newLabelsArray.push(aggregatePowerLabels[index]);
                    newValuesArray.push(aggregatePowerValues[index]);
                    return false;
                }
            });

            let resultArray=[];
            if(filterOtherValues.length==0){
                resultArray = [{
                    insidetextorientation: "radial",
                    type: "pie",
                    labels:newLabelsArray,
                    values:newValuesArray
                }]
            } else {
                resultArray = [{
                    insidetextorientation: "radial",
                    type: "pie",
                    labels:[...newLabelsArray, 'Others'],
                    values:[...newValuesArray, _.sum(filterOtherValues)]
                }]
            }

            console.log('resultArray',resultArray);

            setPieFilteredData(resultArray);
        }
    }
  },[powerPlantsData, reservesData, countries, year, countrySelected, multiRange]);

  return (
    <>
      <Fab
        color="primary"
        size="medium"
        aria-label="Details"
        className={classes.fabIcon}
        onClick={handleInfoModalClick}
      >
        <MyLocationIcon />
      </Fab>
      <Paper
        elevation={1}
        className={`${classes.infoModal} ${openModal ? "open" : null}`}
      >
        <Grid xs={12} container style={{ position: "relative" }}>
          <div className={classes.flagContainer}>
            {countries.length>0 && countries.map((value, idx) => (
                <img
                    src={selectedFlag(value.code)}
                    alt={value.name}
                    key={idx}
                    onClick={() => setCountrySelected(value)}
                />
            ))}
          </div>
          <AppBar position="static" className={classes.popOverTitleBar}>
            <Toolbar>
              <IconButton
                  className={classes.menuPopOverButton}
                  color="inherit"
                  aria-label="menu"
              >
                {countries.length>0 ? (
                    <img
                        src={selectedFlag(countrySelected?.code)}
                        alt=""
                        style={{ width: 35, height: 35 }}
                    />
                ) : (

                    <Typography variant="h6" noWrap style={{ display: "flex" }}>
                      {(!!selectedPowerReservesParentId && selectedPowerReservesParentId == 2) ? "SAARC Region Reserves" : "SAARC Region"}
                    </Typography>
                )}
              </IconButton>
              <Typography variant="h6" noWrap style={{ display: "flex" }}>
                {countrySelected?.name}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.popOverContent}>
            <Typography variant="subtitle1">
                {(!!selectedPowerReservesParentId && selectedPowerReservesParentId == 2) ? "Cumulative Production" : "Power Generation Capacity in MW"}
            </Typography>
            <Typography variant="subtitle2">YEAR - {year}</Typography>
            <Typography variant="body2">
              {/*{isEmpty(yearData) ? (*/}
              {/*    "Data not available for this year"*/}
              {/*) : (*/}
              {/*    <>*/}
              {/*      {!!yearData.value ? numberWithCommas(yearData.value) : ""}&nbsp;*/}
              {/*      {yearData.unit}*/}
              {/*    </>*/}
              {/*)}*/}
            </Typography>
          </div>
          <Grid className={classes.chartCnt} container xs={12}>
              {pieFilteredData.length ?
              (<Plot
                data={pieFilteredData}
                ref={chartRef}
                var layout = {{
                  height: 200,
                  width: 300,
                  showlegend: false,
                  textposition: 'inside',
                  margin: {"t": 20, "b": 70, "l": 0, "r": 0},
                }}
                config={{
                  mapboxAccessToken:
                      "pk.eyJ1IjoibWVocmVlbmFzdGFmbmFzIiwiYSI6ImNrYTZtczE3MzA4ZG0yeWw3cnczdmh4enYifQ.0gV4wvyM_izuh5wni8ahrw",
                displayModeBar: false
                }}

            />) :  <Typography variant="p" noWrap style={{padding:'0 0 8px 16px'}} >
                      No data
                  </Typography>
              }
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
