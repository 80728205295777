import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root:{
        flex:'1',
        height:'calc(100vh - 90px)',
        position:'relative',
        overflow:'hidden'
    },
    menuPopOverButton:{
        padding:theme.spacing(0,1)
    },
    popOverTitleBar:{
        alignItems:'center'
    },
    overLay: {
        height: "100%",
        position: "absolute",
        zIndex: 999,
        background: "rgba(255,255,255, 0.5)",
    },

}));

export default useStyles;