import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import useStyles from "./styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import instance, {BASE_URL, JSON_CONFIG} from "./../../axios";
import Loader from './../Loader';

export default () => {
    const classes = useStyles();
    const [tableData, setTableData] = useState([]);
    const [searchTerm, setSearchTerm] = useState([]);
    const [resultForTerm, setResultForTerm] = useState('');
    const [displayLoader, setDisplayLoader] = useState(false);

    const callDocumentsApi=(term)=>{
        instance.get(`${BASE_URL}/policies/search/${term}`, JSON_CONFIG)
            .then(
                response => {
                    setTableData(response.data);
                    (response.data.length>0) ? setResultForTerm(term) : setResultForTerm('No result found');
                    setDisplayLoader(false);
                },
                error => {
                    console.log('error',error);
                    setResultForTerm("No result found");
                    setDisplayLoader(false);
                }
            )
            .catch(
                error => {
                    console.log('error',error);
                    setResultForTerm("No result found");
                    setDisplayLoader(false);
                }
            )
    }

    const handleSearch=(event)=>{
        event.preventDefault();
        if(searchTerm !==""){
            setDisplayLoader(true);
            callDocumentsApi(searchTerm);
        }
    }

    const splitName=(doc)=>{
        let array = doc.split('/');
        return array[array.length-1];
    }

    return(
        <div className={classes.root}>
            <Loader display={displayLoader}/>
            <AppBar position="static">
                <Toolbar>
                    <Typography className={classes.title} variant="h6" noWrap>
                        Document Search
                    </Typography>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <form noValidate autoComplete="off" onSubmit={handleSearch}>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e)=> {setSearchTerm(e.target.value)}}
                        />
                        </form>
                    </div>
                </Toolbar>
            </AppBar>
            <Typography className={classes.searchResultTerm} variant="h6">
                {!!resultForTerm && (
                    resultForTerm === "No result found" ? resultForTerm : `Search results for query: "${resultForTerm}"`
                ) }
            </Typography>

            {tableData.map((item,index)=>(
                <Card variant="outlined" className={classes.cardRoot} key={index}>
                    <CardContent className={classes.cardContent}>
                        <Typography className={classes.itemTitle} variant="h5" >
                            {item.title}
                        </Typography>
                        <Typography className={classes.country} component="p">
                            {item.country+' '+item.effective_year}
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                                <strong>Draft Year:</strong> {item.year}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <strong>Effective Start Year:</strong> {item.effective_year}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <strong>Document Type:</strong> {item.category}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <strong>Issued by:</strong> The Government of {item.country}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <strong> Source:</strong> {item.source}
                            </Grid>
                        </Grid><br/>
                        <strong>Overall Summary:</strong>
                        <Typography variant="body2" component="p">
                            {item.summary}
                        </Typography>

                    </CardContent>
                    <CardActions>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            href={`https://diceanalytics.com/api/policies/search/download_${splitName(item.document)}`}
                            download
                        >
                            Download Policy
                        </Button>
                    </CardActions>
                </Card>
            ))}

        </div>
    )
}


