import React, {useEffect, useState} from 'react';
import useStyles from './styles';
import {useSelector, useDispatch} from "react-redux";
import API from "../app/actions";
import Plot from "react-plotly.js";
import Slider from "../slider";
import _ from "lodash";
import ReactDOM from "react-dom";
import InfoTradeFlowModal from "./infoTradeFlowModal";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {SET_TRADE_FLOW_TYPE,RESET_SELECTED_TRADE_FLOW_INDICATORS} from './../app/actionTypes';

function kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

const INITIAL_DATA = [{
    name: '',
    data: [],
    type: "scattermapbox",
    lon:[],
    lat:[],
    marker: {
        color: 'blue',
        size: 8
    }
}];

const DataEnergyTradeFlowPage = ({setDisplayLoader,resetDataFlag}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [mapData, setMapData] = useState(INITIAL_DATA);
    const {year, countries, tradeFlowAPIData, selectedTradeFlowIndicators} = useSelector((state) => state.App);
    useEffect(() => {
        dispatch({
            type: SET_TRADE_FLOW_TYPE,
            payload: 'tradeMap',
        });

        return ()=>{
            dispatch({
                type: SET_TRADE_FLOW_TYPE,
                payload: '',
            });
            dispatch({
                type: RESET_SELECTED_TRADE_FLOW_INDICATORS,
            });
        }
    },[]);

    useEffect(() => {
        if(resetDataFlag){
            setMapData(INITIAL_DATA);
        }

    },[resetDataFlag]);

    useEffect(() => {
        if (tradeFlowAPIData.length && !_.isEmpty(countries) && selectedTradeFlowIndicators.length) {
            let data = [];
            selectedTradeFlowIndicators.map(indicator=>{
                const flow = indicator.flow == 1 ? "Import" : "Export";
                const filterData = tradeFlowAPIData.filter(record=> {
                    if(record.year == year &&
                    record.reporter_country.id == countries.id &&
                    record.flow == flow &&
                    record.commodity.category_id == indicator.id &&
                    record.trade_value >= 10000){
                        return record;
                    }

                });
                data = [...data, ...filterData];
            });
            if(data.length){
                const transformData = data.map(record=>{
                    const value = kFormatter(record.trade_value);
                    return {
                        type: 'scattermapbox', // scattergeo for curved lines
                        line: {
                            width: 5,
                            color: record.flow == 'Import' ?  'blue' : 'red'
                        },
                        hovertemplate: "%{text}",
                        marker: {symbol: "marker", size: 10, color: "green"},
                        mode: "lines+markers",
                        opacity: 0.5,
                        lat: [record.reporter_country.lat, record.partner_country.lat],
                        lon: [record.reporter_country.lng, record.partner_country.lng],
                        name: `$${value} - ${record.commodity.title}`,
                        text:[record.reporter_country.name, record.partner_country.name],
                        width: "90vh"
                    }
                });
                setMapData(transformData);
            } else {
                setMapData(INITIAL_DATA);
            }
        }
    },[countries, selectedTradeFlowIndicators, tradeFlowAPIData, year]);

    return(
         <div className={classes.root} style={{width:`${window.innerWidth-240}px`}}>
             {selectedTradeFlowIndicators.length===0 || _.isEmpty(countries) ? (
                 <Grid
                     xs={12}
                     justify="center"
                     alignItems="center"
                     container
                     item
                     className={classes.overLay}
                 >
                     <Typography variant="h4" component="h4" color="primary">
                         {!selectedTradeFlowIndicators.length
                             ? "Please Select Indicator"
                             : _.isEmpty(countries)
                                 ? "Please Select Country"
                                 : ""}
                     </Typography>
                 </Grid>
             ) : (
                 ""
             )}
             <Plot
        //         // data={[{
        //         //     // scattermapbox
        //         //     type: 'scattermapbox', // scattergeo for curved lines
        //         //     line: {
        //         //         width: 5,
        //         //         color: 'blue'
        //         //     },
        //         //     hovertemplate: "%{text}",
        //         //     lat: [30.375321, 33.93911],
        //         //     lon: [69.345116, 67.709953],
        //         //     marker: {symbol: "marker", size: 10, color: "green"},
        //         //     mode: "lines+markers",
        //         //     name: "$94.39 M  - Briquettes",
        //         //     opacity: 0.5,
        //         //     text:["Pakistan", "Afghanistan"],
        //         //     width: "90vh"
        //         // },{
        //         //     // scattermapbox
        //         //     type: 'scattermapbox', // scattergeo for curved lines
        //         //     line: {
        //         //         width: 5,
        //         //         color: 'blue'
        //         //     },
        //         //     hovertemplate: "%{text}",
        //         //     marker: {symbol: "marker", size: 10, color: "green"},
        //         //     mode: "lines+markers",
        //         //     opacity: 0.5,
        //         //     lat: [30.375321, 41.153332],
        //         //     lon: [69.345116, 20.168331],
        //         //     name: "$4.86K  - Briquettes",
        //         //     text:["Pakistan", "Albania"],
        //         //     width: "90vh"
        //         // }]}
                 data={mapData}
                 layout= {{
                     title: "Trade Flows",
                     width: window.screen.width,
                     height: window.screen.height-220,
                     legend: {
                         x: 0.8,
                         y: 1
                     },
                     geo: {
                         resolution: 50,
                         showland: true,
                         showlakes: true,
                         landcolor: 'rgb(204, 204, 204)',
                         countrycolor: 'blue'
                     },
                     font: {
                         color: "#b9dfd5"
                     },
                     marker: {
                         size: 24
                     },
                     dragmode: "zoom",
                     mapbox: {
                         scale: 4,
                         center: {
                             lat: 22,
                             lon: 90
                         },
                         domain: {
                             x: [0, 1],
                             y: [0, 1]
                         },
                         style: "light",
                         zoom: 3
                     },
                     margin: {
                         r: 0,
                         t: 0,
                         b: 0,
                         l: 0,
                         pad: 0
                     },
                     paper_bgcolor: "#FFF",
                     plot_bgcolor: "#FFF",
                     showlegend: false
                 }}
                 config={{
                     mapboxAccessToken:
                         "pk.eyJ1IjoibWVocmVlbmFzdGFmbmFzIiwiYSI6ImNrYTZtczE3MzA4ZG0yeWw3cnczdmh4enYifQ.0gV4wvyM_izuh5wni8ahrw",
                 }}

             />

             {React.useMemo(()=> <Slider year={year}/>,[year])}

             {ReactDOM.createPortal(
                 React.useMemo(
                     ()=> <InfoTradeFlowModal/>,
                     []
                 )
                 ,
                 document.querySelector('body')
             )}

         </div>
    )
}

export default DataEnergyTradeFlowPage;