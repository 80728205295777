import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  logo: {
    textAlign: "center",
    backgroundColor: theme.palette.common.white,
    width: 240,
    display: "flex",
    flexDirection: "row",
    textDecoration: "none",
  },
  logoImg: {
    display: "block",
    width: 87,
    height: 85,
    float: "right",
    "& img": {
      width: 85,
    },
  },
  logoText: {
    padding: theme.spacing(1, 0, 0, 1),
    color: theme.palette.primary.main,
    fontSize: 19,
    textAlign: "left",
    textDecoration: "none",
    fontWeight: 500,
    // textShadow:'0 2px 1px #c3c3c3',
    fontFamily: "Roboto, georgia, serif",
    lineHeight: 1.3,
    flex: 1,
    textTransform: "uppercase",
    letterSpacing: "0.05em",
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.contrastText,
    boxShadow: "none",
    flex: 1,
  },
  toolBar: {
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(0, 2),
    height: 48,
    minHeight: 48,
    overflow: "hidden",
    borderBottom: "#dcdcdc 1px solid",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  navLink: {
    backgroundColor: "transparent",
    color: theme.palette.secondary.contrastText,
    textDecoration: "none",
    padding: theme.spacing(2),
    marginRight: 1,
    cursor: "pointer",
    "&:visited": {
      backgroundColor: "transparent",
      color: theme.palette.secondary.contrastText,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&.is-active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&.hidden": {
      display: "none",
    },
  },
  arrowLink: {
    display: "flex",
    alignItems: "inherit",
  },
  dropDownMenu: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default useStyles;
