import AF from "./resources/images/flags/AF.png";
import BD from "./resources/images/flags/BD.png";
import BT from "./resources/images/flags/BT.png";
import IN from "./resources/images/flags/IN.png";
import LK from "./resources/images/flags/LK.png";
import MV from "./resources/images/flags/MV.png";
import NP from "./resources/images/flags/NP.png";
import PK from "./resources/images/flags/PK.png";

export const selectedFlag = (country) => {
    switch (country) {
        case "AF":
            return AF;
        case "BD":
            return BD;
        case "BT":
            return BT;
        case "IN":
            return IN;
        case "LK":
            return LK;
        case "MV":
            return MV;
        case "NP":
            return NP;
        case "PK":
            return PK;
        case "Afghanistan":
            return AF;
        case "Bangladesh":
            return BD;
        case "Bhutan":
            return BT;
        case "India":
            return IN;
        case "Sri Lanka":
            return LK;
        case "Maldives":
            return MV;
        case "Nepal":
            return NP;
        case "Pakistan":
            return PK;
        default:
            return "";
    }
};

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const colorsArray =  [
    '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
];



export const COMPARE_HEADER = {
    number: 'S.No',
    category: 'Category',
    subcategory: 'Sub Category',
    indicator_name: 'Indicator Name',
    value: 'Value',
    unit: 'Unit',
    country: 'Country',
    year: 'Year',
};

// JSON TO CSV EXPORT
function convertToCSV(objArray) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i += 1) {
        let line = '';

        Object.keys(array[i]).map((key) => {
            if (line !== '') line += ',';

            line += array[i][key];
            return key;
        });
        str += `${line}\r\n`;
    }
    return str;
}

function exportCSVFile(items, fileTitle) {
    // Convert Object to JSON
    const jsonObject = JSON.stringify(items);
    const csv = convertToCSV(jsonObject);
    const exportedFileName = `${fileTitle}.csv` || 'export.csv';

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFileName);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', exportedFileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export function generateCSV(title, data) {
    const fileTitle = `SAARC_${title}`;
    exportCSVFile(data, fileTitle);
}

export function filterExportData(dataToExport) {
    return dataToExport.map((row) => {
        const newRow = row;
        Object.keys(newRow).map((key) => {
            const value = row[key] !== null ? String(row[key]) : '';
            newRow[key] = (value.indexOf(',') > -1)
                ? `"${value.replace(/"/g, '""')}"`
                : value.replace(/"/g, '""');
            return row[key];
        });
        return newRow;
    });
}
