import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root:{
        flex:'1',
        height:'calc(100vh - 90px)',
        position:'relative',
        overflow:'hidden'
    },
    tabsCnt: {
        display: "inline-block",
        float: "right",
        background: "red",
        width: 110,
        position: "relative",
        "& > .MuiTabs-root": {
            minHeight: 43,
        },
        [theme.breakpoints.up('lg')]: {
            width: 170,
            zIndex:2000
        },
    },
    tabs: {
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        flexDirection: "column",
        minHeight: 43,
        position:'relative',
        zIndex:5,
        "& .MuiTab-root": {
            minHeight: 25,
            padding: "5px 2px",
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
            "& .MuiTab-wrapper img": {
                width: 50,
            },
            "&.Mui-selected": {
                backgroundColor: "#e9b831",
            },
            "&.Mui-disabled": {
                backgroundColor: "#9cd2cd"
            },
        },
    },
    tabPanel: {
        flex: 1,
        height: "calc(100vh - 88px)",
        "& .MuiBox-root": {
            padding: 0,
        },
    },
    resetButton: {
        borderBottom: `1px solid ${theme.palette.divider} !important`,
        backgroundColor: '#ffc935',
        borderRadius: `0 !important`,
        width: "70px !important",
        position: "absolute !important",
        right: "0 !important",
        top: "-38px !important",
        display: "block !important",
        zIndex: "9999 !important",

        [theme.breakpoints.up('lg')]: {
            position:'relative',
            top:"0 !important",
            padding:'9px 0 !important',
            width:'60px !important',
            minWidth:'60px !important'
        },
    },
    overLay: {
        height: "100%",
        position: "absolute",
        zIndex: 100,
        background: "rgba(255,255,255, 0.5)",
    },
    tradeFlowPage:{
        position:'absolute',
        top:46,
        right:0
    }

}));

export default useStyles;