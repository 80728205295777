import React, { useEffect, useState } from "react";
import _ from 'lodash';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import useStyles from "./styles";
import instance, {BASE_URL} from "./../../axios";
import {useDispatch, useSelector} from "react-redux";
import API from "../app/actions";

const LeftMenuInfraMap = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedPowerReservesIndicators } = useSelector((state) => state.App);
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [data, setData] = useState([]);
  const [copyData, setCopyData] = useState([]);
  const [openAccordian, setOpenAccordian] = useState(false);
  const [expanded, setExpanded] = useState("");
  const [childExpanded, setChildExpanded] = useState("");

  useEffect(() => {
    instance.get(`${BASE_URL}/powerplants/types`)
      .then((res) => {
        setData([
          {
            parent: "Infrastructure",
            id:1,
            child: [
              {
                childName: "PowerPlants",
                child_id:1,
                childOfChild: [...res.data],
              },
              {
                childName: "Reserves",
                child_id:2,
                childOfChild: [
                  {
                    id: 29,
                    name: "Natural Gas",
                  },
                  {
                    id: 101,
                    name: "Oil",
                  },
                ],
              },
            ],
          },
        ]);
        setCopyData([
          {
            parent: "Infrastructure",
            id:1,
            child: [
              {
                childName: "PowerPlants",
                child_id:1,
                childOfChild: [...res.data],
              },
              {
                childName: "Reserves",
                child_id:2,
                childOfChild: [
                  {
                    id: 29,
                    name: "Natural Gas",
                  },
                  {
                    id: 101,
                    name: "Oil",
                  },
                ],
              },
            ],
          },
        ]);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (searchFieldValue.length > 0) {
      setOpenAccordian(true);

      let _childOfChild,
          child = [];
      let itemIndex = [];
      let childIndex = [];
      const result = data.filter(
          (item, index) => {
            child = item?.child.filter((childItem, index) => {
              const childOfChild = childItem?.childOfChild.filter(
                  (childOfChildItem, index) => {
                    return childOfChildItem?.name
                        ?.toLowerCase()
                        .includes(searchFieldValue.toLowerCase());
                  }
              );
              delete childItem.childOfChild;
              childItem.childOfChild = childOfChild;

              if (
                  childItem?.childName
                      ?.toLowerCase()
                      .includes(searchFieldValue.toLowerCase())
              ) {
                itemIndex.push(index);
                return item;
              } else if (childOfChild.length) {
                return item;
              }
            });
            delete item.child;
            item.child = child;
            if (
                item?.parent
                    ?.toLowerCase()
                    .includes(searchFieldValue.toLowerCase())
            ) {
              itemIndex.push(index);
              return item;
            } else if (child.length > 0) {
              return item;
            }
          }
      );
      setData(result);

    } else {
      setOpenAccordian(false);
      const cloneData = _.cloneDeep(copyData);
      setData([...cloneData]);
    }
  },[searchFieldValue]);

  const handleParentChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleChildChange = (panel) => (event, newExpanded) => {
    setChildExpanded(newExpanded ? panel : false);
  };


  return (
    <>
      <div className={classes.root}>
        <div className={classes.searchAndMenuCnt}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              name="searchField"
              placeholder="Search indicator"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearchFieldValue(e.target.value);
              }}
              value={searchFieldValue}
              autoComplete="off"
            />
          </div>
          {data.map((value, key) => (
            <Accordion key={key}
                       // expanded={openAccordian || expanded === value.id}
                       expanded={true}
                       onChange={handleParentChange(value.id)}>
              <AccordionSummary
                classes={{ root: classes.summary }}
                style={{display:'none'}}
                // expandIcon={<ExpandMoreIcon className={classes.white} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {value.parent}
                </Typography>
              </AccordionSummary>
              {value.child.map((child, idx) => (
                <AccordionDetails
                  key={idx}
                  className={classes.accordionMainDetails}
                >
                  <Accordion className={classes.subAccordion}
                             expanded={openAccordian ||  childExpanded === child.child_id}
                             onChange={handleChildChange(child.child_id)}
                  >
                    <AccordionSummary
                      className={classes.summary}
                      expandIcon={<ExpandMoreIcon className={classes.white} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        {child.childName}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MenuList  className={classes.menuList}>
                        {child.childOfChild.map((childOfChild,index) => (
                          <MenuItem key={`${childOfChild.name}${index}`}
                                    className={selectedPowerReservesIndicators.some((value) => value.id === childOfChild.id)
                                        ? "Mui-selected"
                                        : ""}
                                    onClick={(event) => {
                                      let flag = selectedPowerReservesIndicators.some(value => value.id === childOfChild.id);
                                      dispatch(API.setSelectedPowerReservesIndicators(childOfChild, child.child_id,!flag));
                                    }}
                          >
                            <Typography
                              variant="inherit"
                              className={classes.childOfChildName}
                            >
                              {childOfChild.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              ))}
            </Accordion>
          ))}
        </div>
      </div>
    </>
  );
};

export default LeftMenuInfraMap;
