import React from "react";
import { Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Header from "../header";
import LeftMenu from "../leftMenu";
import useStyles from "./styles";
import LeftMenuInfraMap from "../leftMenu/infraMapLeftMenu";
import LeftMenuTradeFlow from "../leftMenu/tradeFlowLeftMenu";
import DownloadPdf from "./../app/downloadPdf";
import Footer from "./../footer";

const RouteHOC = ({ component: Component, ...rest }) => {
  const classes = useStyles();

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Header />
            <Container disableGutters className={classes.root}>
              {props.match.url === "/inframaps" ? (
                <LeftMenuInfraMap />
              ) : props.match.url === "/data/energy-trade-flow" ? (
                <LeftMenuTradeFlow />
              ) : (
                <LeftMenu match={props.match} />
              )}

              <main className={classes.grow}>
                <div className={classes.content}>
                  <Component {...props} />
                </div>
                <Footer />
              </main>
            </Container>
            {props.match.url === "/data/indicators" && <DownloadPdf />}
          </>
        );
      }}
    />
  );
};

export default RouteHOC;
