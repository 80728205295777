import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";

// const middleware = [thunk];
const middleware = [thunk, reduxImmutableStateInvariant()];


// middleware.push(createLogger({
//     duration: true,
//     diff: true
// }));

let composedMiddleWare = compose(applyMiddleware(...middleware));

if (!!window.__REDUX_DEVTOOLS_EXTENSION__) {
  composedMiddleWare = compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
  );
}

const store = createStore(rootReducer, {}, composedMiddleWare);

export default store;
