import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import Toolbar from "@material-ui/core/Toolbar";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import useStyles from "./styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {SET_COUNTRIES_FOR_MAP} from './../app/actionTypes';
import API from './../app/actions';

export default ({ location }) => {
  const classes = useStyles();
  const { exclusiveCountry, countriesList, countries } = useSelector((state) => state.App);
  const dispatch = useDispatch();
  const [hasCountryBar, setCountryBar] = useState(false);

  useEffect(() => {

    const containsCountry = [
      "/data/indicators",
      "/data/energy-trade-flow",
      "/data/compare",
      "/inframaps",
      "/policy/timeline",
    ];

    if (!!containsCountry.includes(location.pathname)) {
      setCountryBar(true);
    } else {
      setCountryBar(false);
    }

    return ()=> {
      dispatch({
        type: SET_COUNTRIES_FOR_MAP,
        payload: [],
      });
    }

  }, [location]);

  useEffect(() => {
    if (hasCountryBar && !countriesList.length) {
      dispatch(API.getCountriesList());
    }

  }, [hasCountryBar]);

  const handleFormat = (event, newFormats) => {
    if(newFormats !== null){
      dispatch({
        type: SET_COUNTRIES_FOR_MAP,
        payload: newFormats,
      });
    }

  };

  return (
    <>
      {!!hasCountryBar ? (
        <Toolbar className={classes.countryToolBar}>
          {/*<LocationOnIcon className={classes.locationOnIcon} />*/}
          <ToggleButtonGroup
            className={classes.toggleButtonGroup}
            value={countries}
            onChange={handleFormat}
            aria-label="text formatting"
            exclusive={exclusiveCountry}
          >
            {countriesList.map((value, idx) => (
              <ToggleButton value={value} key={idx} aria-label={value.name}>
                {value.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Toolbar>
      ) : null}
    </>
  );
};
