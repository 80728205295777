import React, { useCallback, useEffect } from "react";
import useStyles, { CustomSlider } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import API from "../app/actions";

const d = new Date();
const MAX_YEAR = d.getFullYear() - 1;

export default ({ multiRangeYear }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const marks = [
    {
      value: 2000,
      label: "2000",
    },
    {
      value: MAX_YEAR,
      label: MAX_YEAR.toString(),
    },
  ];

  function valuetext(value) {
    return value;
  }

  const handleChange = (event, newValue) => {
    if (multiRangeYear !== newValue) {
      dispatch(API.setMultiRangeYearMap(newValue));
    }
  };

  return (
    <div className={classes.root}>
      <CustomSlider
        // defaultValue={2019}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="on"
        step={1}
        marks
        min={2000}
        max={MAX_YEAR}
        value={multiRangeYear}
        onChangeCommitted={handleChange}
      />
    </div>
  );
};
