import React, {useEffect, useState} from 'react';
import useStyles from './styles';
import {useSelector, useDispatch} from "react-redux";
import Plot from "react-plotly.js";
import _ from "lodash";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Slider from "../slider";
import {RESET_SELECTED_TRADE_FLOW_INDICATORS, SET_TRADE_FLOW_TYPE} from "../app/actionTypes";

const COLORS =  ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

const SankeyGraph = ({setDisplayLoader,resetDataFlag}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [indicatorSelected, setIndicatorSelected] = useState({});
    const [sankeyData, setSankeyData] = useState([]);
    const {year, countries, tradeFlowAPIData, selectedTradeFlowIndicators} = useSelector((state) => state.App);

    useEffect(() => {
        dispatch({
            type: SET_TRADE_FLOW_TYPE,
            payload: 'sneakyGraph',
        });
        if(resetDataFlag){
            setSankeyData([]);
        }

        return ()=>{
            dispatch({
                type: SET_TRADE_FLOW_TYPE,
                payload: '',
            });
            dispatch({
                type: RESET_SELECTED_TRADE_FLOW_INDICATORS,
            });
        }
    },[]);

    useEffect(() => {
        setIndicatorSelected(selectedTradeFlowIndicators[selectedTradeFlowIndicators.length - 1]);
    }, [selectedTradeFlowIndicators[selectedTradeFlowIndicators.length - 1]]);

    useEffect(() => {
        if (tradeFlowAPIData.length && !_.isEmpty(countries) && !_.isEmpty(indicatorSelected)) {
            let obj = {
                reporter_country: {
                    name: countries.name
                },
                flow: 'Export',
                commodity: {
                    category_id: parseInt(indicatorSelected.id)
                },
                year: year
            };
            console.log('obj', obj);
            let new_response = _.filter(tradeFlowAPIData, obj);
            console.log('new_response', new_response);
            if(new_response.length && new_response.length > 0) {
                let categoryArray  = [];
                let countriesArray  = [];
                let valuesArray = [];
                let sourceArray = [];
                let labels  = [];

                // add the indicator in case of one indicator selection
                labels.push(new_response[0].commodity.title);

                new_response.forEach(elem => {
                    countriesArray.push(elem.partner_country.name);
                    categoryArray.push(elem.commodity.title);
                    valuesArray.push(elem.trade_value);
                    sourceArray.push(elem.reporter_country.name);
                });

                labels  = labels.concat(countriesArray);
                let source  = [];
                let target  = [];
                countriesArray.forEach((e,index) => {
                    source.push(0);
                    target.push(index+1);
                });

                let link = {};
                link = {
                    source: source,
                    target:target,
                    value: valuesArray
                };
                console.log('link', link);
                console.log( 'labels', labels);
                const data = {
                    type: "sankey",
                    legendonly: true,
                    orientation: "h",
                    node: {
                        hovertemplate:"Price: %{label}",
                        pad: 15,
                        // thickness: ,
                        line: {
                            color: "black",
                            width: 0.5
                        },
                        label:labels,
                        color: COLORS
                    },
                    link: link
                    // link: {
                    //   source: [0],
                    //   target: [0],
                    //   value: [200008]
                    // }
                };
                console.log('data',data);
                setSankeyData([data]);
            } else{

            }
        }
    },[countries, indicatorSelected, tradeFlowAPIData, year]);

    return(
        <div className={classes.root} style={{width:`${window.innerWidth-240}px`}}>
            {selectedTradeFlowIndicators.length===0 || _.isEmpty(countries) ? (
                <Grid
                    xs={12}
                    justify="center"
                    alignItems="center"
                    container
                    item
                    className={classes.overLay}
                >
                    <Typography variant="h4" component="h4" color="primary">
                        {!selectedTradeFlowIndicators.length
                            ? "Please Select Indicator"
                            : _.isEmpty(countries)
                                ? "Please Select Country"
                                : ""}
                    </Typography>
                </Grid>
            ) : (
                ""
            )}
            {sankeyData.length>0 && (
            <Plot
                data={sankeyData}
                layout= {{
                    title: `SAARC TradeFlows ${countries.name}`,
                    width: window.screen.width-240,
                    height: window.screen.height-250,
                    font: {
                        size: 10
                    }}}
                config={{
                    mapboxAccessToken:
                        "pk.eyJ1IjoibWVocmVlbmFzdGFmbmFzIiwiYSI6ImNrYTZtczE3MzA4ZG0yeWw3cnczdmh4enYifQ.0gV4wvyM_izuh5wni8ahrw",
                    showSendToCloud: true
                }}

            />)}
            {React.useMemo(()=> <Slider year={year}/>,[year])}
        </div>
    )
}

export default SankeyGraph;