import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root:{
        textAlign:'center',
        '& > img':{
            maxWidth:'100%'
        }
    }
}));

export default useStyles;