import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Slider from "../slider";
import {
  COMPARE_HEADER,
  filterExportData,
  generateCSV,
  selectedFlag,
  numberWithCommas,
} from "../../utils";
import API from "../app/actions";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import { selectedFlag, numberWithCommas } from "./utils";

const DataComparePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { year, countries, compareIndicators, indicatorAPIData } = useSelector(
    (state) => state.App
  );
  const [tableData, setTableData] = useState([]);
  const [preparedTableData, setPreparedTableData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDownloadMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!indicatorAPIData.length) {
      dispatch(API.getIndicatorApiData());
    }
  }, []);

  useEffect(() => {
    if (countries.length && indicatorAPIData.length) {
      let filteredData = [];
      countries.map((selectedCountry) => {
        let data = [];
        compareIndicators.map((indicator) => {
          console.log("indicator", indicator);
          data = indicatorAPIData.filter((record) => {
            return (
              indicator.categoryId == record.category_id &&
              indicator.subCategoryId == record.subcategory_id &&
              indicator.id == record.indicator_id &&
              record.year == year &&
              record.code.toUpperCase() == selectedCountry.code.toUpperCase()
            );
          });
          if (data.length) filteredData = [...filteredData, ...data];
          else {
            const result = indicatorAPIData.find(
              (ind) =>
                ind.category_id == indicator.categoryId &&
                ind.subcategory_id == indicator.subCategoryId &&
                ind.indicator_id == indicator.id
            );

            filteredData = [
              ...filteredData,
              {
                category: result.category,
                category_id: result.category_id, //parseInt(Math.random()*100000),
                code: selectedCountry.code,
                country: selectedCountry.name,
                indicator_id: indicator.id,
                indicator_name: indicator.name,
                subcategory: result.subcategory,
                subcategory_id: indicator.subCategoryId,
                unit: result.unit,
                value: 0,
                year: year,
              },
            ];
          }
        });
      });

      //PREPARE DATA FOR TABLE
      if (compareIndicators.length && filteredData.length) {
        let prepareTableData = [];
        filteredData.map((outer) => {
          let preparedRow = {
            subcategory: outer.subcategory,
            indicator_name: outer.indicator_name,
            category_id: outer.category_id,
            subcategory_id: outer.subcategory_id,
            indicator_id: outer.indicator_id,
            unit: outer.unit,
          };
          let countryArr = [];
          let valueArr = [];
          filteredData.map((inner) => {
            if (
              outer.category_id == inner.category_id &&
              outer.subcategory_id == inner.subcategory_id &&
              outer.indicator_id == inner.indicator_id
            ) {
              countryArr.push(inner.country);
              if (inner.value > 0) {
                let val = inner.value.toFixed(2);
                valueArr.push(numberWithCommas(val));
              } else {
                valueArr.push(0);
              }
            }
          });
          preparedRow = { ...preparedRow, countryArr, valueArr };
          // console.log('preparedRow',preparedRow);

          if (prepareTableData.length) {
            const result = prepareTableData.filter((item) => {
              return (
                item.category_id == preparedRow.category_id &&
                item.subcategory_id == preparedRow.subcategory_id &&
                item.indicator_id == preparedRow.indicator_id
              );
            });

            if (result.length === 0) {
              prepareTableData.push(preparedRow);
            }
          } else {
            prepareTableData.push(preparedRow);
          }
        });
        setPreparedTableData(prepareTableData);
      }

      //PREPARE DATA FOR CSV DOWNLOAD
      if (filteredData.length) {
        const transformData = filteredData.map((row, index) => {
          return {
            number: index + 1,
            category: row.category,
            subcategory: row.subcategory,
            indicator_name: row.indicator_name,
            value: row.value,
            unit: row.unit,
            country: row.country,
            year: row.year,
          };
        });
        setTableData(transformData);
      } else {
        setTableData(filteredData);
      }
    }

    if (!compareIndicators.length) {
      setTableData([]);
      setPreparedTableData([]);
    }
  }, [compareIndicators, countries, year, indicatorAPIData]);

  const downlaodCSVHandler = (e) => {
    // Download CSV button click

    if (indicatorAPIData.length) {
      let transformData = [];
      let dataToExport = [];
      if (e.target.value === 1) {
        transformData = indicatorAPIData.map((row, index) => {
          return {
            number: index + 1,
            category: row.category,
            subcategory: row.subcategory,
            indicator_name: row.indicator_name,
            value: row.value,
            unit: row.unit,
            country: row.country,
            year: row.year,
          };
        });

        //Downlaod all data
        dataToExport = [COMPARE_HEADER, ...transformData];
      } else if (e.target.value === 2) {
        //Download selected data
        dataToExport = [COMPARE_HEADER, ...tableData];
      }
      dataToExport = filterExportData(dataToExport);
      generateCSV("Compare Indicators", dataToExport);
      handleDownloadMenuClose();
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Data Compare for year {year}
          </Typography>
          <Button
            color="secondary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleDownloadMenuClick}
          >
            Download as CSV
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleDownloadMenuClose}
          >
            <MenuItem value={1} onClick={downlaodCSVHandler}>
              All Data
            </MenuItem>
            <MenuItem
              value={2}
              onClick={downlaodCSVHandler}
              disabled={!!tableData && !tableData.length}
            >
              Selected Only
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <div className={classes.tableCnt}>
        <Table
          className={classes.table}
          aria-label="Data compare records table"
        >
          <TableHead>
            <TableRow>
              <TableCell component="th">Indicator</TableCell>
              {Array.isArray(countries) &&
                countries.map((country, index) => (
                  <TableCell component="th" key={country.id} align="center">
                    <img
                      src={selectedFlag(country.code)}
                      alt={country.name}
                      className={classes.flag}
                    />
                    {country.name}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {preparedTableData.map((row, index) => {
              return (
                <TableRow key={row.number}>
                  <TableCell component="th" scope="row">
                    <strong>
                      {`${row.subcategory} ${row.indicator_name}`}
                      <br />({row.unit})
                    </strong>
                  </TableCell>
                  {row.valueArr.map((value, index) => {
                    return (
                      <TableCell component="th" key={index} align="center">
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

      {React.useMemo(
        () => (
          <Slider year={year} />
        ),
        [year]
      )}
    </div>
  );
};

export default DataComparePage;
