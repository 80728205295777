import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Highcharts from "highcharts";
import _ from "lodash";
import HighchartsReact from "highcharts-react-official";
import useStyles from "./styles";
import { CircularProgress, Grid } from "@material-ui/core";
import map from "../../../resources/helper/countries.geo.json";
import API from "./../../app/actions";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import InfoModal from "../../infoModal";
import Slider from "../../slider";

// Load Highcharts modules
require("highcharts/modules/map")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/offline-exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

// countriesChart.mapZoom(0.2);

// if (window.screen.width > 1366) {
//     // for Larege screen
//     this.countriesChart.mapZoom(
//         0.25,
//         this.countriesChart.xAxis[0].toValue(xOffset + 50),
//         this.countriesChart.yAxis[0].toValue(yOffset + 20),
//         xOffset - xOffset * 0.9,
//         -350
//     );
// } else {
//     this.countriesChart.mapZoom(
//         0.25,
//         this.countriesChart.xAxis[0].toValue(xOffset + 50),
//         this.countriesChart.yAxis[0].toValue(yOffset + 30),
//         xOffset - xOffset * 0.6,
//         10
//     );
// }

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const DataIndicatorsPage = () => {
  const classes = useStyles();
  let history = useHistory();
  const chartRef = useRef();
  const dispatch = useDispatch();
  const {
    category,
    subCategory,
    indicator,
    countries,
    countriesList,
    year,
    indicatorAPIData,
  } = useSelector((state) => state.App);
  const [loading, setLoading] = useState(true);
  // const [mapDataFlag, setMapDataFlag] = useState(false);
  const [currentMapData, setCurrentMapData] = useState([]);
  const [countrySelected, setCountrySelected] = useState({});
  const [seriesData, setSeriesData] = useState({});
  const [yearData, setYearData] = useState({});
  const ref = useRef(null);

  let xOffset = (window.innerWidth - 88) * 0.51;
  let yOffset = (window.innerHeight - 240) * 0.25;

  let dimensions = {
    height: window.innerHeight - 88,
    width: window.innerWidth - 240,
  };

  let initialMapOptions = {
    chart: {
      height: dimensions.height,
      width: dimensions.width,
      map: map,
      spacingBottom: 0,
      yAxis: {
        title: {
          text: "",
        },
        min: 0,
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    mapNavigation: {
      buttonOptions: {
        x: 120,
        style: {
          top: "150px",
          position: "absolute",
        },
        theme: {
          fill: "white",
          "stroke-width": 1,
          stroke: "silver",
          r: 0,
          states: {
            hover: {
              fill: "#a4edba",
            },
            select: {
              stroke: "#039",
              fill: "#a4edba",
            },
          },
        },
        // alignTo: "spacingBox"
        verticalAlign: "middle",
        // align: 'right'
      },
      enabled: true,
      enableDoubleClickZoomTo: true,
    },
    colorAxis: {
      minColor: "#EAE2CB",
      maxColor: "#4e71b8",
      type: "logarithmic",
      margin: 10,
      className: "leftAxis",
    },
    legend: {
      align: "left",
      verticalAlign: "middle",
      y: 190,
      x: 120,
      floating: true,
      borderWidth: 1,
      backgroundColor: "white",
      symbolHeight: 20,
    },
    series: [
      {
        data: [
          {
            code: "CU",
            country: "Cuba",
            name: "Total Population",
            subCategory: "Demographics",
            unit: "Thousands",
            value: 1,
            year: 2016,
          },
        ],
        joinBy: ["ISO_A2", "code"],
        states: {
          hover: {
            color: "#609182",
          },
        },
        dataLabels: {
          enabled: true,
          color: "#FFFFFF",
          format: "{point.country}",
        },
        tooltip: {
          valueDecimals: 2,
        },
      },
    ],
  };

  const afterChartCreated = (chart) => {
    // setChartInstance(chart);
    console.log("window.innerWidth", window.innerWidth);
    console.log("window.innerHeight", window.innerHeight);

    let xAxisValue =
      window.innerWidth > 1600
        ? xOffset - 10
        : window.innerWidth < 1100
        ? xOffset - 40
        : xOffset - 25;
    let yAxisValue =
      window.innerHeight > 800
        ? yOffset + 150
        : window.innerHeight > 700
        ? yOffset + 160
        : yOffset + 130;
    chart.mapZoom(
      0.2,
      chart.xAxis[0].toValue(xAxisValue),
      chart.yAxis[0].toValue(yAxisValue),
      -350,
      0
    );
    //chart.reflow();
  };

  // useEffect(()=>{
  //     if(chartInstance!==undefined){
  //         chartInstance.redraw();
  //     }
  // }, [chartInstance, dimensions]);
  useEffect(() => {
    setCountrySelected(countries[countries.length - 1]);
  }, [countries[countries.length - 1]]);

  useEffect(() => {
    if (!indicatorAPIData.length) {
      dispatch(API.getIndicatorApiData());
    }
  }, []);

  useEffect(() => {
    if (indicatorAPIData.length && countriesList.length) {
      setLoading(false);
    }
  }, [indicatorAPIData, countriesList]);

  useEffect(() => {
    if (
      indicatorAPIData.length &&
      countriesList.length &&
      year !== "" &&
      !_.isEmpty(indicator) &&
      !_.isEmpty(category) &&
      !_.isEmpty(subCategory)
    ) {
      // const array = [];
      let innerObj = [];
      countriesList.map((country) => {
        indicatorAPIData.filter((value) => {
          if (
            category.id === value.category_id &&
            subCategory.child_id === value.subcategory_id &&
            indicator.id === value.indicator_id &&
            country.code === value.code
          ) {
            if (year === value.year) {
              innerObj.push({
                value: value.value.toFixed(3),
                name: value?.indicator_name,
                subCategory: value?.subcategory,
                unit: value?.unit,
                country: value?.country,
                year: value?.year,
                code: value?.code,
              });
            }
          }
        });
      });
      // setMapDataFlag(true);

      // if(innerObj.length){
      setCurrentMapData(innerObj);
      // } else {
      //   setCurrentMapData(initialMapOptions);
      // }

      console.log("innerObj", innerObj);

      setLoading(false);

      // Highcharts.mapZoom(
      //   0.3,
      //   //chart.xAxis[0].toValue(xOffset + 200),
      //   //chart.yAxis[0].toValue(yOffset + 80),
      //   //xOffset - xOffset * 0.6,
      //   //10
      //   null,
      //   null,
      //   -350,
      //   0
      // );
    }

    //     const debouncedHandleResize = debounce(function handleResize() {
    //       // setDimensions({
    //       //     height: window.innerHeight - 88,
    //       //     width: window.innerWidth - 240
    //       // });
    //
    //       history.replace("/");
    // }, 1000);
    //
    //     window.addEventListener("resize", debouncedHandleResize);
    //
    //     return () => {
    //       window.removeEventListener("resize", debouncedHandleResize);
    //     };
  }, [indicatorAPIData, year, countriesList, indicator, category, subCategory]);

  // if(!!chartRef && chartRef.current){
  //     const chart = chartRef.current.chart;
  //
  //    // chartRef.current.get('de').zoomTo();
  //    //  chartRef.current.chart.mapZoom(
  //    //      0.3,
  //    //      //chart.xAxis[0].toValue(xOffset + 200),
  //    //      //chart.yAxis[0].toValue(yOffset + 80),
  //    //      //xOffset - xOffset * 0.6,
  //    //      //10
  //    //      null,
  //    //      null,
  //    //      -350,
  //    //      0
  //    //      );
  // }

  useEffect(() => {
    let yd = {};
    if (
      indicatorAPIData.length &&
      year !== "" &&
      !_.isEmpty(countrySelected) &&
      !_.isEmpty(indicator)
    ) {
      let array = [];
      indicatorAPIData.filter((value) => {
        if (
          category.id === value.category_id &&
          subCategory.child_id === value.subcategory_id &&
          indicator.id === value.indicator_id &&
          countrySelected?.name === value.country
        ) {
          if (year === value.year) {
            yd = value;
            array.push({
              y: value?.indicator_name.includes("%")
                ? value.value * 100
                : value.value,
              color: "red",
              name: value?.indicator_name,
              x: value.year,
            });
          } else
            array.push({
              y: value?.indicator_name.includes("%")
                ? value.value * 100
                : value.value,
              color: "#7cb5ec",
              name: value?.indicator_name,
              x: value.year,
            });
        }
      });

      if (!array.length) {
        array.push({
          y: 0,
          color: "",
          name: indicator.name,
          x: 2000,
        });
        array.push({
          y: 0,
          color: "",
          name: indicator.name,
          x: 2020,
        });
      }

      setSeriesData(array);
      setYearData(yd);
      console.log("array", array);
      console.log("yd", yd);

      if (ref && ref.current) {
        ref.current.chart.reflow();
      }
    }
  }, [indicator.id, countrySelected, year]);

  return (
    <div className={classes.root}>
      {loading ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.loader}
        >
          <CircularProgress />
        </Grid>
      ) : _.isEmpty(currentMapData) ? (
        <HighchartsReact
          options={initialMapOptions}
          constructorType={"mapChart"}
          highcharts={Highcharts}
          containerProps={{ style: { height: "100%", width: "100%" } }}
          callback={afterChartCreated}
          ref={chartRef}
        />
      ) : (
        <HighchartsReact
          options={{
            chart: {
              height: dimensions.height,
              width: dimensions.width,
              map: map,
              spacingBottom: 0,
              yAxis: {
                title: {
                  text: "",
                },
                min: 0,
              },
            },
            credits: {
              enabled: false,
            },
            title: {
              text: "",
            },
            mapNavigation: {
              buttonOptions: {
                x: 120,
                style: {
                  top: "150px",
                  position: "absolute",
                },
                theme: {
                  fill: "white",
                  "stroke-width": 1,
                  stroke: "silver",
                  r: 0,
                  states: {
                    hover: {
                      fill: "#a4edba",
                    },
                    select: {
                      stroke: "#039",
                      fill: "#a4edba",
                    },
                  },
                },
                // alignTo: "spacingBox"
                verticalAlign: "middle",
                // align: 'right'
              },
              enabled: true,
              enableDoubleClickZoomTo: true,
            },
            colorAxis: {
              minColor: "#EAE2CB",
              maxColor: "#4e71b8",
              type: "logarithmic",
              margin: 10,
              className: "leftAxis",
            },
            legend: {
              align: "left",
              verticalAlign: "middle",
              y: 190,
              x: 120,
              floating: true,
              borderWidth: 1,
              backgroundColor: "white",
              symbolHeight: 20,
            },
            series: [
              {
                data: currentMapData,
                joinBy: ["ISO_A2", "code"],
                states: {
                  hover: {
                    color: "#609182",
                  },
                },
                dataLabels: {
                  enabled: true,
                  color: "#FFFFFF",
                  format: "{point.country}",
                },
                name: `${currentMapData[0].subCategory} ${currentMapData[0].name}`,
                tooltip: {
                  valueSuffix: ` ${currentMapData[0].unit}`,
                  valueDecimals: 2,
                },
              },
            ],
            exporting: {
              fallbackToExportServer: false,
              accessibility: {
                enabled: true,
              },
              enabled: true,
              showTable: false,
              buttons: {
                contextButton: {
                  align: "left",
                  x: 10,
                  // menuItems:["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
                },
              },
            },
          }}
          constructorType={"mapChart"}
          highcharts={Highcharts}
          containerProps={{ style: { height: "100%", width: "100%" } }}
          callback={afterChartCreated}
          ref={chartRef}
        />
      )}
      {ReactDOM.createPortal(
        React.useMemo(
          () => (
            <InfoModal
              // category={category}
              subCategory={subCategory}
              indicator={indicator}
              countries={countries}
              year={year}
              yearData={yearData}
              seriesData={seriesData}
              countrySelected={countrySelected}
              setCountrySelected={setCountrySelected}
              chartRef={ref}
            />
          ),
          [indicator, year, yearData, seriesData, countrySelected]
        ),
        document.querySelector("body")
      )}
      {React.useMemo(
        () => (
          <Slider year={year} />
        ),
        [year]
      )}
    </div>
  );
};

export default DataIndicatorsPage;
