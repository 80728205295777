import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root:{
        padding:theme.spacing(2),
        margin:'0 auto',
        maxWidth:800
    },
    heading: {
        fontSize: 16,
        fontWeight: 400,
    },
    summary:{
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.common.white,
        '& .MuiAccordionSummary-content':{
            margin:'16px 0'
        },
        '& .MuiAccordionSummary-expandIcon':{
            color:theme.palette.common.white
        }
    },
    faqAccordian:{
        padding:theme.spacing(2),
        background:theme.palette.common.white
    }
}));

export default useStyles;