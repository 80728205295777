import React from 'react';
import DataIndicatorsPage from '../dataIndicatorsPage';
import DataEnergyTradeFlowPage from '../dataEnergyTradeFlowPage';
import DataComparePage from '../dataComparePage';

const DataPage = ({match}) => {
    if(match.params.dataId === 'energy-trade-flow')
    return (<DataEnergyTradeFlowPage/>)
    else if(match.params.dataId === 'compare')
    return (<DataComparePage/>)
    else
    return (<DataIndicatorsPage/>)
};

export default DataPage;


