import React, {useCallback, useEffect} from "react";
import useStyles, { CustomSlider } from "./styles";
import {useDispatch, useSelector} from "react-redux";
import API from "../app/actions";

export default({multiRange, selectedPowerReservesParentId}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  // const [value, setValue] = React.useState([0, 5000]);

  const marks = [
    {
      value: 0,
      label: "0 MW",
    },
    {
      value: 500,
      label: "",
    },
    {
      value: 1000,
      label: "",
    },
    {
      value: 1500,
      label: "",
    },
    {
      value: 2000,
      label: "",
    },
    {
      value: 2500,
      label: "",
    },
    {
      value: 3000,
      label: "",
    },
    {
      value: 3500,
      label: "",
    },
    {
      value: 4000,
      label: "",
    },
    {
      value: 4500,
      label: "",
    },
    {
      value: 5000,
      label: "5000 MW",
    },
  ];

  function valuetext(value) {
    return value;
  }

  const handleChange =(event, newValue) => {

    if(multiRange !== newValue){
      dispatch(API.setMultiRangeMap(newValue));
    }
  };

  if(selectedPowerReservesParentId === 1){
    return (
        <div className={classes.root}>
          <CustomSlider
              defaultValue={0}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="on"
              step={100}
              marks={marks}
              min={0}
              max={5000}
              value={multiRange}
              onChangeCommitted={handleChange}
          />
        </div>
    )
  } else return true

};
