import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import useStyles from "./styles";
// import HomePage from '../homePage';
import DataPage from "../dataPage";
import ExpertsPage from "../expertsPage";
import ResourceMapsPage from "../resourceMapsPage";
import FaqsPage from "../faqsPage";
import FeedbackPage from "../feedbackPage";
import InframapsPage from "../inframapsPage";
import PolicyPage from "../policyPage";
import CalorificPage from "../calorificPage";
import RouteHOC from "../routeHOC";
import API from "./actions";

import ComparePage from "../comparePage";

function App(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(API.getLeftMenu());
  }, []);

  return (
    <Router>
      <CssBaseline />
      <Switch>
        <Route
          exact
          path={`/`}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: "/data/indicators",
                state: { from: location },
              }}
            />
          )}
        />
        <RouteHOC path="/data/:dataId" component={DataPage} />
        <RouteHOC exact path={`/experts`} component={ExpertsPage} />
        <RouteHOC exact path={`/resource-maps`} component={ResourceMapsPage} />
        <RouteHOC exact path={`/faqs`} component={FaqsPage} />
        <RouteHOC exact path={`/feedback`} component={FeedbackPage} />
        <RouteHOC exact path={`/inframaps`} component={InframapsPage} />
        <RouteHOC path={`/policy/:policyId`} component={PolicyPage} />
        <RouteHOC exact path={`/calorific`} component={CalorificPage} />
      </Switch>
    </Router>
  );
}

export default App;
