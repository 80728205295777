import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";
import Fab from "@material-ui/core/Fab";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import { Paper, Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {useDispatch, useSelector} from "react-redux";
import { selectedFlag } from "./../../../utils";
import _ from "lodash";

function converToKMB (labelValue)
{
    return Math.abs(Number(labelValue)) / 1.0e+6;
    // Nine Zeroes for Billions
    // return Math.abs(Number(labelValue)) >= 1.0e+9
    //
    //     ? Math.abs(Number(labelValue)) / 1.0e+9 //+ "B"
    //     // Six Zeroes for Millions
    //     : Math.abs(Number(labelValue)) >= 1.0e+6
    //
    //         ? Math.abs(Number(labelValue)) / 1.0e+6 //+ "M"
    //         // Three Zeroes for Thousands
    //         : Math.abs(Number(labelValue)) >= 1.0e+3
    //
    //             ? Math.abs(Number(labelValue)) / 1.0e+3 //+ "K"
    //
    //             : Math.abs(Number(labelValue));
}

export default () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [notAvailable, setNotAvailable] = useState(false);
    const [indicatorSelected, setIndicatorSelected] = useState({});
    const [tableData, setTableData] = useState([]);
    const {year, countries, tradeFlowAPIData,selectedTradeFlowIndicators, tradeFlowIndicators} = useSelector((state) => state.App);

    const handleInfoModalClick = () => {
        setOpenModal(!openModal);
    };


    useEffect(() => {
        setIndicatorSelected(selectedTradeFlowIndicators[selectedTradeFlowIndicators.length - 1]);
    }, [selectedTradeFlowIndicators[selectedTradeFlowIndicators.length - 1]]);

    useEffect(()=>{
        if(!_.isEmpty(countries)){
            setOpenModal(true);
            console.log('indicatorSelected',indicatorSelected);

            if(tradeFlowAPIData.length){
                let filterData=[];
                if(!_.isEmpty(indicatorSelected)){
                    const flow = indicatorSelected.flow == 1 ? "Import" : "Export";
                    filterData = tradeFlowAPIData.filter(record=> {
                        if(record.year == year &&
                            record.reporter_country.id == countries.id &&
                            record.flow == flow &&
                            record.commodity.category_id == indicatorSelected.id){
                            return record;
                        }

                    });
                    console.log('filterData',filterData);

                } else {
                    // If only country is selected
                    filterData = tradeFlowAPIData.filter(record=> {
                        if(record.year == year &&
                            record.reporter_country.id == countries.id &&
                            record.flow == "Import"){
                            return record;
                        }

                    });
                    console.log('filterData',filterData);
                }
                if(filterData.length){
                    setNotAvailable(false);
                    let arr=[];
                    let commodityArray = _.map(_.uniqBy(filterData,'commodity_id'),'commodity');
                    console.log('commodityArray',commodityArray);

                    commodityArray.map(commodity=>{
                        let trade_value_sum = 0;
                        const data = filterData.map(record=>{
                            if(commodity.id ===record.commodity_id){
                                trade_value_sum += record.trade_value
                            }
                        });
                        let valueFormated = converToKMB(trade_value_sum);
                        valueFormated = Number(valueFormated).toFixed(1);
                        arr.push({
                            commodity,
                            trade_value_sum,
                            valueFormated
                        })
                    })
                    console.log('arr',arr);
                    setTableData(arr);
                } else {
                    setNotAvailable(true);
                    setTableData([]);
                }
            }
        }

    },[year, countries,tradeFlowAPIData,indicatorSelected]);

    return (
        <>
            {!_.isEmpty(countries) && (
                <Fab
                    color="primary"
                    size="medium"
                    aria-label="Details"
                    className={classes.fabIcon}
                    onClick={handleInfoModalClick}
                >
                    <MyLocationIcon />
                </Fab>)}
            <Paper
                elevation={1}
                className={`${classes.infoModal} ${openModal ? "open" : null}`}
            >
                <Grid xs={12} container style={{ position: "relative" }}>
                    <AppBar position="static" className={classes.popOverTitleBar}>
                        <Toolbar>
                            <IconButton
                                className={classes.menuPopOverButton}
                                color="inherit"
                                aria-label="menu"
                            >
                                {!_.isEmpty(countries) && (
                                    <>
                                        <img
                                            src={selectedFlag(countries?.code)}
                                            alt=""
                                            style={{ width: 35, height: 35, marginRight:10 }}
                                        />
                                        <Typography variant="h6" noWrap style={{ display: "flex" }}>
                                            {countries?.name}
                                        </Typography>
                                    </>
                                )}
                            </IconButton>

                        </Toolbar>
                    </AppBar>
                    <div className={classes.popOverContent}>
                        <Typography variant="subtitle2" style={{padding:'0 16px'}}>YEAR - {year}</Typography>
                        <Typography variant="subtitle2" style={{padding:'0 16px 8px'}}>Trade value in Million USD</Typography>
                        {tableData.length>0 && (
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableBody>
                                    {tableData.map((row,index) => (
                                        <TableRow key={row.commodity.id}>
                                            <TableCell component="th" scope="row"><strong>{row.commodity.title}</strong></TableCell>
                                            <TableCell align="left">{row.valueFormated}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}

                        {notAvailable && (
                            <Typography variant="subtitle2" style={{padding:'0 16px'}}>No data available</Typography>
                        )}
                    </div>
                    <Grid className={classes.chartCnt} container xs={12}>

                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
