import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import SliderYearMultiRange from "../../sliderYearMultiRange";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/offline-exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const PanelContent1 = ({ data }) => {
  const classes = useStyles();
  const { multiRangeYear, indicator, subCategory } = useSelector(
    (state) => state.App
  );
  const [chartData, setChartData] = useState("line");

  useEffect(() => {
    //console.log('dataaaa',data);
    if (!!data && !!data.series && data.series.length) {
      data.series.map((seriesRow) => {
        seriesRow.data.map((record) => {
          if (record.color !== "") record.color = "";
          return record;
        });
        return seriesRow;
      });
    }
    //console.log('dataaaa after',data);

    let transformData = {
      ...data,
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: multiRangeYear[0],
        },
      },
      title: {
        text: `${subCategory.ind_name} ${indicator.name}, ${multiRangeYear[0]}-${multiRangeYear[1]}`,
      },

      subtitle: {
        text: !!data.series && data.series.length ? data?.series[0]?.unit : "",
      },
      yAxis: {
        title: {
          text: indicator.name,
        },
      },
      xAxis: {
        labels: { enabled: true },
        allowDecimals: false,
      },
    };

    setChartData(transformData);
    console.log("transformData", transformData);
  }, [multiRangeYear, data]);

  return (
    <div className={classes.root}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartData}
        containerProps={{
          style: {
            height: window.innerHeight - 250 + "px",
            width: "100%",
          },
        }}
      />
      {React.useMemo(
        () => (
          <SliderYearMultiRange multiRangeYear={multiRangeYear} />
        ),
        [multiRangeYear]
      )}
    </div>
  );
};

export default PanelContent1;
