import React, {useState, useEffect} from 'react';
import useStyles from "./styles";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';


export default ({display}, ...props) => {
    const classes = useStyles();
    // const [tableData, setTableData] = useState([]);

    return(
        <div className={`${classes.root} ${!!display ? 'display': null}`}>
            <CircularProgress color="primary" className={classes.progressLoading} />
        </div>
    )
}


