import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  downloadPDFButton:{
    width:150,
    zIndex:1000,
    position:'absolute',
    left:theme.spacing(1),
    bottom:theme.spacing(1),
    '&:hover':{
      background:'#ffffff'
    }
  },

}));

export default useStyles;
